import React from 'react';
import { Input } from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'Formik';
import FormFrame from 'features/ui/FormFrame';
import FormRow from 'features/ui/FormRow';
import CreatorsTypeSelect from 'features/creators/lib/CreatorsTypeSelect';

import {
  TYPE_AUTHOR,
  TYPE_LECTURER,
  TYPE_NARRATOR,
  TYPE_SPEAKER, TYPE_TRANSLATOR,
} from 'features/creators/consts';
import {
  ICreator,
  IFormValues,
} from 'features/creators/types';
import ImageUploader from 'features/ui/ImageUploader';

interface IBaseFormProps extends FormikProps<IFormValues>, WithTranslation {
  item?: ICreator;
}

class BaseForm extends React.PureComponent<IBaseFormProps> {
  onChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      handleChange,
      setFieldValue,
    } = this.props;
    if (e.target.validity.valid) {
      handleChange(e);
      setFieldValue('avatar', e.target.files[0]);
    }
  };

  renderAuthorFields() {
    const {
      t,
      values,
      handleBlur,
      handleChange,
    } = this.props;

    return (
      <React.Fragment>
        <FormRow label={t('short_name')} id="short_name">
          <Input
            type="text"
            id="short_name"
            name="short_name"
            value={values.short_name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow label={t('first_name')} id="first_name" required>
          <Input
            type="text"
            id="first_name"
            name="first_name"
            value={values.first_name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow label={t('last_name')} id="last_name" required>
          <Input
            type="text"
            id="last_name"
            name="last_name"
            value={values.last_name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow
          label={t('avatar')}
          id="avatar"
          text={t('forms:expected_size', { width: 400, height: 400 })}
        >
          <ImageUploader
            id="avatar"
            name="avatar"
            value={values.avatar}
            onChange={this.onChangeImage}
            onBlur={handleBlur}
            imageWidth={400}
            imageHeight={400}
          />
        </FormRow>
      </React.Fragment>
    );
  }

  render() {
    const {
      t,
      item,
      values,
      handleBlur,
      handleChange,
    } = this.props;
    const id = item ? item.id : undefined;
    const shouldRenderAuthorFields = [TYPE_AUTHOR, TYPE_LECTURER, TYPE_NARRATOR, TYPE_SPEAKER, TYPE_TRANSLATOR].includes(values.type);
    const isFullNameRequired = !shouldRenderAuthorFields;
    return (
      <FormFrame
        id={id}
        cancelLink="/creators"
        {...this.props}
      >
        <FormRow label={t('type')} id="type">
          <CreatorsTypeSelect
            id="type"
            name="type"
            value={values.type}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow
          id="full_name"
          label={t(shouldRenderAuthorFields ? 'full_name' : 'title')}
          required={isFullNameRequired}
        >
          <Input
            type="text"
            id="full_name"
            name="full_name"
            value={values.full_name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        {shouldRenderAuthorFields ? this.renderAuthorFields() : null}

        <FormRow id="description" label={t('description')}>
          <Input
            type="textarea"
            id="description"
            name="description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

      </FormFrame>
    );
  }
}

export default withTranslation('creators')(BaseForm);
