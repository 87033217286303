import React from 'react';
import { Input } from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'formik';
import DatePicker from 'features/ui/DatePicker';
import BaseItemForm from 'features/items/lib/Form/Form';
import {
  RETAIL_ANDROID_BUNDLE_ID,
  RETAIL_IOS_BUNDLE_ID,
  RETAIL_WEB_BUNDLE_ID,
} from 'features/offers/consts';

import { IFormValues } from 'features/paperbooks/types';
import { IItem, Moment } from 'features/types';
import LanguagesSelect from 'features/languages/LanguagesSelect';
import Switch from 'features/ui/Switch';
import ItemImageUploader from 'features/paperbooks/lib/ItemImageUploader';
import ItemFileUploader from 'features/paperbooks/lib/ItemFileUploader';
import CommissionAgentSelect from 'features/paperbooks/lib/CommissionAgentSelect';
import FormRow from 'features/ui/FormRow';

interface IBaseFormProps extends FormikProps<IFormValues>, WithTranslation {
  item?: IItem;
}

class BaseForm extends React.PureComponent<IBaseFormProps> {
  onChangeEntityId = (field: string) => (id: number) => {
    this.props.setFieldValue(field, id);
  };

  onBlurISBN = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { values } = this.props;
    if (!values.prices.filter(p => !p.delete).length) {
      this.props.setFieldValue('prices', [
        {
          reference_price: 379,
          bundle_id: RETAIL_ANDROID_BUNDLE_ID,
          in_app_id: `gpe${e.target.value}`,
        },
        {
          reference_price: 379,
          bundle_id: RETAIL_IOS_BUNDLE_ID,
          in_app_id: `ru.alpinabook.reader.iphone.${e.target.value}.worldwide`,
        },
        {
          reference_price: 379,
          bundle_id: RETAIL_WEB_BUNDLE_ID,
          in_app_id: `ru.alpinabook.reader.web.${e.target.value}`,
        },
      ]);
    }
  };

  onBlur = (field: string) => {
    this.props.setFieldTouched(field, true);
  };

  onChangeDate = (date: Moment | null) => {
    this.props.setFieldValue('paperbook.release_date', date ? date.format('YYYY-MM-DD') : null);
  };

  onChangeCommissionAgent = (value, data) => {
    this.props.setFieldValue('paperbook.commissionAgent', +value);
  }

  resolveFields = (fields: React.ReactNode[]) => {
    const {
      t,
      values,
      item,
      handleBlur,
      handleChange,
    } = this.props;

    { item && item.id
      ? fields.splice(8, 0, (
        <>
          <FormRow
            key="mediaimage"
            id="mediaimage"
            label={t('mediaimage')}
          >
            <ItemImageUploader id={item.id} name="mediaimage" images={values.itemMediaImages} />
          </FormRow>
          <FormRow
            key="mediafile"
            id="mediafile"
            label={t('mediafile')}
          >
            <ItemFileUploader id={item.id} name="mediafile" files={values.itemMediaFiles} />
          </FormRow>
        </>
      ))
      : null; }

    fields.splice(18, 0, (
      <FormRow key="originalLanguage" label={t('originalLanguage')} id="originalLanguage">
        <LanguagesSelect
          id="originalLanguage"
          name="originalLanguage"
          value={values.originalLanguage}
          onChange={this.onChangeEntityId('originalLanguage')}
          onBlur={this.onBlur.bind(this, 'originalLanguage')}
        />
      </FormRow>
    ));
    fields.splice(12, 0, [
      (
        <FormRow
          key="paperbook.publication_type"
          id="paperbook.publication_type"
          label={t('publication_type')}
          required
        >
          <Input
            id="'paperbook.publication_type"
            name="paperbook.publication_type"
            value={values.paperbook.publication_type}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>
      ),
      (
      <FormRow
        key="paperbook.width"
        id="paperbook.width"
        label={t('width')}
        required
      >
        <Input
          type="number"
          id="paperbook.width"
          name="paperbook.width"
          value={values.paperbook.width}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
      ), (
      <FormRow
        key="paperbook.height"
        id="paperbook.height"
        label={t('height')}
        required
      >
        <Input
          type="number"
          id="paperbook.height"
          name="paperbook.height"
          value={values.paperbook.height}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
      ), (
      <FormRow
        key="paperbook.weight"
        id="paperbook.weight"
        label={t('weight')}
        required
      >
        <Input
          type="number"
          id="paperbook.weight"
          name="paperbook.weight"
          value={values.paperbook.weight}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
      ), (
      <FormRow
        key="paperbook.thickness"
        id="paperbook.thickness"
        label={t('thickness')}
        required
      >
        <Input
          type="number"
          id="paperbook.thickness"
          name="paperbook.thickness"
          value={values.paperbook.thickness}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
      ),(
      <FormRow
        key="paperbook.paper_pages"
        id="paperbook.paper_pages"
        label={t('paper_pages')}
        required
      >
        <Input
          type="number"
          id="paperbook.paper_pages"
          name="paperbook.paper_pages"
          value={values.paperbook.paper_pages}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
      ), (
      <FormRow
        key="paperbook.circulation"
        id="paperbook.circulation"
        label={t('circulation')}
        required
      >
        <Input
          type="number"
          id="paperbook.circulation"
          name="paperbook.circulation"
          value={values.paperbook.circulation}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
      ), (
      <FormRow
        key="paperbook.format"
        id="paperbook.format"
        label={t('format')}
        required
      >
        <Input
          id="paperbook.format"
          name="paperbook.format"
          value={values.paperbook.format}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
      ), (
      <FormRow
        key="paperbook.release_date"
        id="paperbook.release_date"
        label={t('release_date')}
        required
      >
        <DatePicker
          id="paperbook.release_date"
          value={Date.parse(values.paperbook.release_date)}
          onChange={this.onChangeDate}
        />
      </FormRow>
      ), (
      <FormRow
        key="paperbook.is_preorder"
        id="paperbook.is_preorder"
        label={t('is_preorder')}
      >
        <Switch
          id="paperbook.is_preorder"
          name="paperbook.is_preorder"
          checked={values.paperbook.is_preorder}
          onChange={handleChange}
        />
      </FormRow>
      ), (
      <FormRow
        key="paperbook.is_coming_soon"
        id="paperbook.is_coming_soon"
        label={t('is_coming_soon')}
      >
        <Switch
          id="paperbook.is_coming_soon"
          name="paperbook.is_coming_soon"
          checked={values.paperbook.is_coming_soon}
          onChange={handleChange}
        />
      </FormRow>
      ), (
      <FormRow
        key="paperbook.is_always_in_stock"
        id="paperbook.is_always_in_stock"
        label={t('is_always_in_stock')}
      >
        <Switch
          id="paperbook.is_always_in_stock"
          name="paperbook.is_always_in_stock"
          checked={values.paperbook.is_always_in_stock}
          onChange={handleChange}
        />
      </FormRow>
      ), (
      <FormRow
        key="paperbook.isbn"
        label={t('isbn')}
        id="paperbook.isbn"
        text="Пример: 9785459011050"
      >
        <Input
          type="text"
          id="paperbook.isbn"
          name="paperbook.isbn"
          value={values.paperbook.isbn}
          onChange={handleChange}
          onBlur={this.onBlurISBN}
        />
      </FormRow>
      ), (
        <FormRow
          key="paperbook.commissionAgent"
          label={t('commissionAgent')}
          id="paperbook.commissionAgent"
          name="paperbook.commissionAgent"
        >
          <CommissionAgentSelect
            id="paperbook.commissionAgent"
            name="paperbook.commissionAgent"
            value={values.paperbook.commissionAgent}
            onChange={this.onChangeCommissionAgent}
            onBlur={handleBlur}
          />
        </FormRow>
      )]);
    return fields;
  };

  render() {
    return (
      <BaseItemForm
        {...this.props}
        resolveFields={this.resolveFields}
        cancelLink="/paperbooks"
      />
    );
  }
}

export default withTranslation('paperbooks')(BaseForm);
