import React, { Component } from 'react';
import { match as IMatch } from 'react-router';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { History } from 'history';
import { set } from 'lodash';

import CardPage from 'features/ui/Page/CardPage';
import IndexTableBase, {
  IControlFields,
} from 'features/common/IndexTableBase';
import { ICursor } from 'features/types';
import makeColumns from './columns';
import getSeriesListQuery from '../queries/getSeriesList.gql';
import deleteSeriesQuery from '../queries/deleteSeries.gql';
import { makeSeriesUrl } from '../lib/url';
import { ISeries } from '../types';
import { FEATURE_ICON } from '../consts';

interface ISeriesTableProps extends WithTranslation {
  data: any;
  fetchMore: any;
  isLoading: boolean;
  items: any;
  history: History;
  match: IMatch<object>;
  pagination: any;
}

interface ISeriesTableState {
  id?: string;
  name?: string;
  page?: string;
  perPage?: string;
  items: ISeries[];
  isLoading: boolean;
  pagination: ICursor;
}

class PageSeries extends Component<ISeriesTableProps, ISeriesTableState> {
  private _columns: object[];

  constructor(props: ISeriesTableProps) {
    super(props);
    this._columns = makeColumns();
  }

  prepareRequestData(variables: any, data: IControlFields) {
    if (data.id) {
      variables.id = [data.id];
    }
    if (data.name) {
      set(variables, 'filterByFields.name', {
        value: String(data.name),
        operator: 'like',
      });
    }
    if (data.offers) {
      set(variables, 'filterByFields.offers', {
        operator: '=',
        value: String(data.offers),
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <CardPage
        icon={FEATURE_ICON}
        title={t('series')}
        buttons={[{ link: makeSeriesUrl({ action: 'create' }), name: t('new_series') }]}
      >
        <IndexTableBase
          filterFields={['id', 'name', 'offers']}
          query={getSeriesListQuery}
          queryResultName="SerieQuery"
          columns={this._columns}
          prepareRequestData={this.prepareRequestData}
          deleteMutation={deleteSeriesQuery}
          makeCrudUrl={makeSeriesUrl}
          showAutoEditIconColumn={true}
          showViewIconColumn={true}
        />
      </CardPage>
    );
  }
}

export default withTranslation('series')(PageSeries);
