import React, { ReactText } from 'react';
import { Input } from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'formik';
import FormFrame from 'features/ui/FormFrame';
import FormRow from 'features/ui/FormRow';
import ItemsSelect from 'features/items/lib/ItemsSelect';
import { makeSeriesUrl } from '../url';
import {
  ISeries,
  ISeriesFormValues,
} from '../../types';

interface ISeriesFormProps extends FormikProps<ISeriesFormValues>, WithTranslation {
  series?: ISeries;
}

class BaseSeriesForm extends React.Component<ISeriesFormProps> {
  onItemsChange = (value: ReactText) => {
    const { setFieldValue } = this.props;
    setFieldValue('items', value);
  }

  render() {
    const {
      series,
      handleBlur,
      handleChange,
      t,
      values,
    } = this.props;
    return (
      <FormFrame
        id={series ? series.id : undefined}
        cancelLink={makeSeriesUrl()}
        {...this.props}
      >
        <FormRow label={t('name')} id="name" required>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder={t('name')}
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormRow>

        <FormRow label={t('items')} id="items">
          <ItemsSelect
            id="items"
            name="items"
            value={values.items}
            isMulti
            onChange={this.onItemsChange}
          />
        </FormRow>
      </FormFrame>
    );
  }
}

export default withTranslation('series')(BaseSeriesForm);
