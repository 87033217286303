import React from 'react';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import {
  client,
  gql,
} from 'features/graphql';
import {
  IListQueryVariables,
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import {
  IItemQueryResponse,
  ISeriesQueryResponse,
} from 'features/items/types';
import ReactSelectWithId from 'features/ui/ReactSelect/ReactSelectWithId';
import { ISeries } from 'features/series/types';

interface ISeriesSelectProps extends ISharedSelectProps {
  data: ISeries[];
}

interface ISeriesSelectState {
  options: ISelectOption[];
}

class SeriesSelect extends React.PureComponent<ISeriesSelectProps, ISeriesSelectState> {
  static getDerivedStateFromProps(props: ISeriesSelectProps) {
    return {
      options: SeriesSelect.mapItemsToOptions(props.data),
    };
  }

  static mapItemsToOptions = (data: ISeries[]) => data.map((item: ISeries) => ({
    value: +item.id,
    label: item.name,
  }))

  state = {
    options: [],
  };

  loadOptions = (value: string) => client.query<ISeriesQueryResponse>({
    query: QUERY,
    variables: {
      pageNum: 1,
      perPage: 100,
      filterByFields: {
        name: {
          value,
          operator: 'like',
        },
      },
    },
  }).then((res) => {
    if (res.data && res.data.SerieQuery) {
      return SeriesSelect.mapItemsToOptions(res.data.SerieQuery.items);
    }
    return [];
  });

  noOptionsMessage = () => 'Ничего не найдено';

  render() {
    const {
      id,
      name,
      value,
      onBlur,
      onChange,
      onPaste,
    } = this.props;

    const {
      options,
    } = this.state;

    return (
      <ReactSelectWithId
        id={id}
        name={name}
        onPaste={onPaste}
        options={options}
        isMulti
        async
        loadOptions={this.loadOptions}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        noOptionsMessage={this.noOptionsMessage}
        minSearchLength={1}
      />
    );
  }
}

const QUERY = gql`
  query getSeriesForSelect(
    $id: [ID]
    $pageNum: Int
    $perPage: Int
    $filterByFields: SerieFilterByFields
  ) {
    SerieQuery(
      id: $id,
      page: $pageNum,
      perPage: $perPage,
      filterByFields: $filterByFields,
    ) {
      items {
        id
        name
      }
    }
  }
`;

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, IItemQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;
  return {
    data: data && data.SerieQuery ? data.SerieQuery.items : [],
    ...ownProps,
  };
};

export default graphql<ISharedSelectProps, IItemQueryResponse, IListQueryVariables, IItemsSelectProps>(QUERY, {
  props: mapResultsToProps,
  options: (ownProps) => ({
    variables: {
      id: ownProps.value || undefined,
      pageNum: 1,
      perPage: 100,
    },
  }),
})(SeriesSelect);
