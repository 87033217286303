import gql from 'graphql-tag';
import { client } from 'features/graphql';
import { ILayoutQueryResponse } from 'features/items/types';

const QUERY = gql`
  query LayoutsQuery(
    $page: Int
    $perPage: Int
  ) {
  LayoutQuery(
    page: $page
    perPage: $perPage
  ) {
    items {
      id
      name
    }
  }
}
`;

export default () => client.query<ILayoutQueryResponse>({
  query: QUERY,
  fetchPolicy: 'network-only',
  context: {
    isGlobalLoading: true,
  },
  variables: {
    page: 1,
    perPage: 500000,
  },
});
