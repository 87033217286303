import React from 'react';
import { Input } from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'formik';
import FormFrame from 'features/ui/FormFrame';
import FormRow from 'features/ui/FormRow';
import OffersSelectWithButtons from 'features/offers/lib/OffersSelectWithButtons';
import BlocksSelect from 'features/layouts/lib/BlocksSelect';
import LayoutSelect from 'features/layouts/lib/LayoutSelect';
import { client } from 'features/graphql';
import getLayoutQuery from 'features/layouts/queries/getLayoutQuery.gql';
import { makeLayoutUrl } from '../url';
import { ILayout, ILayoutFormValues } from '../../types';

interface ILayoutFormProps extends FormikProps<ILayoutFormValues>, WithTranslation {
  layout?: ILayout;
}

class BaseLayoutForm extends React.Component<ILayoutFormProps> {
  onChange = (name: string, value: number | string | null | string[]) => {
    const { setFieldValue } = this.props;
    setFieldValue(name, value);
  };

  onChangeOffers = (ids: ID[]) => {
    this.onChange('offers', ids.slice());
  };

  onBlocksChange = (values: number[]) => {
    const { setFieldValue } = this.props;
    setFieldValue('blocks', [...values]);
  }

  onTemplateChange = (id: number) => {
    const { setFieldValue } = this.props;
    setFieldValue('template', id);
    client.query({
      fetchPolicy: 'network-only',
      query: getLayoutQuery,
      variables: {
        id,
      },
    }).then((res) => {
      setFieldValue('blocks', res.data.LayoutQuery.items[0].blocks.sort((blockA, blockB) => blockA.sort - blockB.sort));
    }).catch(err => global.console.log(err));
  }

  render() {
    const {
      layout,
      handleBlur,
      handleChange,
      t,
      values,
    } = this.props;

    return (
      <FormFrame
        id={layout ? layout.id : undefined}
        cancelLink={makeLayoutUrl()}
        {...this.props}
      >
        <FormRow label={t('name')} id="name" required>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder={t('name')}
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow label={t('template')} id="template" key="template">
          <LayoutSelect
            id="template"
            name="template"
            value={values.template}
            onChange={this.onTemplateChange}
          />
        </FormRow>
        <FormRow id="blocks" label={t('blocks')} key="blocks">
          <BlocksSelect
            id="blocks"
            name="blocks"
            blocks={values.blocks}
            onChange={this.onBlocksChange}
          />
        </FormRow>

        <FormRow key="offers" label={t('offers:offers')} id="offers">
          <OffersSelectWithButtons
            id="offers"
            name="offers"
            value={values.offers}
            onChange={this.onChangeOffers}
          />
        </FormRow>
      </FormFrame>
    );
  }
}

export default withTranslation('layouts')(BaseLayoutForm);
