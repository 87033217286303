import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import AppRoute from 'features/app/Route';
import PagePaperBooks from 'features/paperbooks/PagePaperBooks';
import PageCreate from 'features/paperbooks/PageCreate';
import PageEdit from 'features/paperbooks/PageEdit';
import PageView from 'features/items/PageView';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';

export default () => (
    <AccessControl permission="paperbooks:access">
      <Switch>
        <AppRoute path="/paperbooks" menuId="paperbooks" component={PagePaperBooks} exact />
        <AppRoute path="/paperbook/create" menuId="paperbooks" component={PageCreate} exact />
        <AppRoute path="/paperbook/edit/:id" menuId="paperbooks" component={PageEdit} exact />
        <AppRoute path="/paperbook/:id" menuId="paperbooks" component={PageView} exact />
        <Route name="404" component={Page404} />
      </Switch>
    </AccessControl>
);
