import React from 'react';
import ReactTable from 'react-table';
import {
  cloneDeep,
  isEqual,
  toNumber,
} from 'lodash';
import { Input } from 'reactstrap';
import PriceTiersSelect from 'features/prices/PriceTiersSelect';
import TaxSelect from 'features/prices/TaxSelect';

import {
  Column,
  ColumnRenderProps,
  RowRenderProps,
} from 'features/types';
import { IPriceValue } from 'features/prices/types';

interface IPricesEditWidgetProps {
  prices: IPriceValue[];
  onChange: (prices: IPriceValue[]) => void;
}

interface IPricesEditWidgetState {
  initialPrices: IPriceValue[];
  prices: IPriceValue[];
}

export default class PricesEditWidget extends React.Component<IPricesEditWidgetProps, IPricesEditWidgetState> {
  static getDerivedStateFromProps(props: IPricesEditWidgetProps, state: IPricesEditWidgetState) {
    if (!isEqual(props.prices, state.initialPrices)) {
      return {
        initialPrices: cloneDeep(props.prices),
        prices: cloneDeep(props.prices),
      };
    }
    return state;
  }

  columns: Column[];

  constructor(props: IPricesEditWidgetProps) {
    super(props);
    this.columns = this.resolveColumns().filter(col => !!col) as Column[];
    this.state = {
      initialPrices: cloneDeep(props.prices),
      prices: cloneDeep(props.prices),
    };
  }

  shouldComponentUpdate(nextProps: IPricesEditWidgetProps, nextState: IPricesEditWidgetState) {
    return (
      !isEqual(nextProps.prices, this.state.initialPrices) ||
      nextState.prices !== this.state.prices
    );
  }

  resolveColumns = () => ([
    {
      accessor: 'id',
      Header: () => (
        <span>
        ID
        <button type="button" className="btn btn-link" onClick={this.onAddRow}>
          <i className="icon-plus icons font-lg" />
        </button>
      </span>
      ),
      maxWidth: 100,
    },
    {
      id: 'tier',
      Header: 'Прайс-тир',
      accessor: (data: IPriceValue) => data,
      maxWidth: 200,
      style: {
        overflow: 'visible',
      },
      Cell: (cellInfo: ColumnRenderProps & RowRenderProps) => {
        const onChange = (id: number) => {
          const prices = [...this.state.prices];
          prices[cellInfo.index!][cellInfo.column.id!] = +id;
          this.setState({ prices }, this.onChange);
        };
        return (
          <PriceTiersSelect
            onChange={onChange}
            value={+this.state.prices[cellInfo.index!][cellInfo.column.id!]!}
          />
        );
      },
    },
    {
      accessor: 'reference_price',
      Header: 'РРЦ',
      maxWidth: 100,
      Cell: this.renderInput,
    },
    {
      accessor: 'old_reference_price',
      Header: 'СРРЦ',
      maxWidth: 100,
      Cell: this.renderInput,
    },
    {
      accessor: 'in_app_id',
      Header: 'InApp Id',
      maxWidth: 100,
      Cell: this.renderInput,
    },
    {
      accessor: 'bundle_id',
      Header: 'App Bundle Id',
      maxWidth: 120,
      Cell: this.renderInput,
    },
    {
      id: 'tax_code',
      Header: 'Код НДС',
      accessor: (data: IPriceValue) => data,
      maxWidth: 240,
      style: {
        overflow: 'visible',
      },
      Cell: (cellInfo: ColumnRenderProps & RowRenderProps) => {
        const onChange = (id: number) => {
          const prices = [...this.state.prices];
          prices[cellInfo.index!][cellInfo.column.id!] = id;
          this.setState({ prices }, this.onChange);
        };
        return (
          <TaxSelect
            onChange={onChange}
            value={+this.state.prices[cellInfo.index!][cellInfo.column.id!]!}
          />
        );
      },
    },
    {
      id: 'actions',
      Header: '',
      maxWidth: 100,
      className: 'text-center',
      accessor: (data: IPriceValue) => data,
      Cell: (row: RowRenderProps) => {
        const onClick = () => this.onDeleteRow(row.index!);
        return (
          <button type="button" className="btn btn-link" onClick={onClick}>
            <i className="icon-trash icons font-lg" />
          </button>
        );
      },
      Filter: () => <div />,
    },
  ]);

  onChange = () => {
    this.props.onChange(this.state.prices);
  }

  onDeleteRow = (index: number) => {
    const { prices } = this.state;
    let newPrices = cloneDeep(prices);
    if (newPrices[index].id) {
      newPrices[index].delete = true;
      // Перемещаю элементы с delete === true в конец массиве, что позволяет
      // обращаться к элементам с delete === undefined по их индексу и
      // сохранить данные об удалении в том же массиве.
      newPrices.sort((a, b) => +!!a.delete - +!!b.delete);
    } else {
      newPrices = newPrices.filter((p, id) => id !== index);
    }
    this.setState(
      { prices: newPrices },
      this.onChange,
    );
  };

  onAddRow = () => {
    const { prices } = this.state;
    this.setState(
      {
        prices: prices.concat([{
          reference_price: 100,
          old_reference_price: 0,
          bundle_id: '',
          in_app_id: '',
          tier: 0,
          tax_code: '4',
        }]),
      },
      this.onChange,
    );
  };

  onChangePrices = (e: React.SyntheticEvent<any>, cellInfo: ColumnRenderProps & RowRenderProps) => {
    const prices = [...this.state.prices];
    if (cellInfo.column.Header === 'РРЦ' || cellInfo.column.Header === 'СРРЦ') {
      prices[cellInfo.index!][cellInfo.column.id!] = toNumber(e.currentTarget.value);
    } else {
      prices[cellInfo.index!][cellInfo.column.id!] = e.currentTarget.value;
    }

    this.setState({ prices }, this.onChange);
  }

  getTableStyleProps = () => ({
    // иначе в ячейках фильтров таблиц не видно выпадающих списков
    style: {
      overflow: 'visible',
    },
  });

  renderInput = (cellInfo: ColumnRenderProps & RowRenderProps) => {
    const onChange = (e: React.SyntheticEvent<any>) => this.onChangePrices(e, cellInfo);
    return (
      <Input
        onBlur={onChange}
        onChange={onChange}
        value={this.state.prices[cellInfo.index!][cellInfo.column.id!] as string}
      />
    );
  };

  filterDeleted(price: IPriceValue) {
    return price.delete !== true;
  }

  renderNoDataComponent = () => null;

  render() {
    const { prices } = this.state;
    return (
      <ReactTable
        showPagination={false}
        data={prices.filter(this.filterDeleted)}
        columns={this.columns}
        minRows={0}
        getTableProps={this.getTableStyleProps}
        getTbodyProps={this.getTableStyleProps}
        NoDataComponent={this.renderNoDataComponent}
        sortable={false}
      />
    );
  }
}
