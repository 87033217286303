import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import { flow } from 'lodash';
import {
  match as IMatch,
  Redirect,
} from 'react-router';

import CardPage from 'features/ui/Page/CardPage';
import LinkBlock from 'features/ui/LinkBlock';
import { IListQueryVariables } from 'features/types';
import deepOmit from 'lib/deepOmit';
import Spinner from 'features/ui/Spinner';
import {
  ISeries,
  ISeriesQueryResponse,
} from '../types';
import SeriesForm from '../lib/SeriesForm';
import {
  makeSeriesUrl,
  makeSeriesUrlTo,
} from '../lib/url';
import getSeriesQuery from '../queries/getSeries.gql';
import editSeriesMutation from '../queries/editSeries.gql';
import { FEATURE_ICON } from '../consts';

interface IPageEditProps extends WithTranslation {
  isLoading: boolean;
  match: IMatch<any>;
  series: ISeries;
}

class PageEdit extends React.Component<IPageEditProps> {
  render() {
    const {
      series,
      isLoading,
      t,
    } = this.props;

    if (!isLoading && !series) {
      return <Redirect to="/404" />;
    }

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <CardPage
        icon={FEATURE_ICON}
        title={series ? `${t('edit_series')}: ${series.name}` : ''}
        crumbs={[{ link: makeSeriesUrl(), name: t('series') }]}
      >
        <LinkBlock viewLink={makeSeriesUrlTo(series, 'view')} />
        <SeriesForm
          series={series}
          mutation={editSeriesMutation}
        />
      </CardPage>
    );
  }
}

const mapResultsToProps = (props: OptionProps<IPageEditProps, ISeriesQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;
  if (data && data.SerieQuery) {
    const series = deepOmit(data.SerieQuery.items[0], '__typename');
    return {
      series,
      isLoading: data && data.loading,
      ...ownProps,
    };
  }
  return {
    series: null,
    isLoading: data && data.loading,
    ...ownProps,
  };
};

export default flow(
  graphql(getSeriesQuery, {
    props: mapResultsToProps,
    options: ({ match }: { match: IMatch<any> }) => {
      const { params } = match;
      const { id } = params || { id: 0 };
      return {
        context: {
          isGlobalLoading: true,
        },
        variables: {
          id: [id],
        },
      };
    },
  }),
  withTranslation('series'),
)(PageEdit);
