import React from 'react';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import * as Yup from 'yup';
import filterChangedValues from 'lib/filterChangedValues';
import { minValidator } from 'lib/validators';
import field from 'lib/field';
import { crudMutate } from 'features/common/helpers';
import { toInt } from 'lib/crudConvert';
import BaseForm from './BaseForm';
import {
  IOffer,
  IOfferSettingsContentFormValues,
  IOfferSettingsContentInput,
} from '../types';

import editOfferQuery from '../queries/editOffer.gql';

interface IFormProps extends WithTranslation {
  offer: IOffer;
}

class Form extends React.Component<IFormProps> {
  getScheme() {
    const { t } = this.props;
    return Yup.object().shape({
      latest_enabled: minValidator({ field: t('content_latest_enabled'), min: 0 }),
      popular_enabled: minValidator({ field: t('content_popular_enabled'), min: 0 }),
      books_max_size: minValidator({ field: t('content_books_max_size'), min: 0 }),
      books_download_limit: minValidator({ field: t('content_books_download_limit'), min: 0 }),
      hybrids_max_count: minValidator({ field: t('content_hybrids_max_count'), min: 0 }),
      hybrids_max_size: minValidator({ field: t('content_hybrids_max_size'), min: 0 }),
      documents_max_count: minValidator({ field: t('content_documents_max_count'), min: 0 }),
      documents_max_size: minValidator({ field: t('content_documents_max_size'), min: 0 }),
      collections_max_count: minValidator({ field: t('content_collections_max_count'), min: 0 }),
      videos_max_count: minValidator({ field: t('content_videos_max_count'), min: 0 }),
      videos_max_size: minValidator({ field: t('content_videos_max_size'), min: 0 }),
      audiobooks_max_count: minValidator({ field: t('content_audiobooks_max_count'), min: 0 }),
      audiobooks_max_size: minValidator({ field: t('content_audiobooks_max_size'), min: 0 }),
    });
  }

  getFormData(): IOfferSettingsContentFormValues {
    const { offer } = this.props;
    let brand;
    if (offer) {
      brand = offer.settingsContent;
    }
    return {
      latest_enabled: field(brand, 'latest_enabled', true),
      popular_enabled: field(brand, 'popular_enabled', true),
      books_enabled: field(brand, 'books_enabled', true),
      paperbooks_enabled: field(brand, 'paperbooks_enabled', true),
      books_max_size: field(brand, 'books_max_size', ''),
      books_download_limit: field(brand, 'books_download_limit', ''),
      books_download_epub_enabled: field(brand, 'books_download_epub_enabled', true),
      books_download_pdf_enabled: field(brand, 'books_download_pdf_enabled', true),
      books_download_mobi_enabled: field(brand, 'books_download_mobi_enabled', true),
      events_calendar_enabled: field(brand, 'events_calendar_enabled', true),
      hybrids_enabled: field(brand, 'hybrids_enabled', true),
      hybrids_max_count: field(brand, 'hybrids_max_count', ''),
      hybrids_max_size: field(brand, 'hybrids_max_size', ''),
      documents_enabled: field(brand, 'documents_enabled', true),
      documents_max_count: field(brand, 'documents_max_count', ''),
      documents_max_size: field(brand, 'documents_max_size', ''),
      collections_enabled: field(brand, 'collections_enabled', true),
      collections_max_count: field(brand, 'collections_max_count', ''),
      videos_enabled: field(brand, 'videos_enabled', true),
      videos_max_count: field(brand, 'videos_max_count', ''),
      videos_max_size: field(brand, 'videos_max_size', ''),
      courses_enabled: field(brand, 'courses_enabled', true),
      audiobooks_enabled: field(brand, 'audiobooks_enabled', true),
      audiobooks_max_count: field(brand, 'audiobooks_max_count', ''),
      audiobooks_max_size: field(brand, 'audiobooks_max_size', ''),
      games_enabled: field(brand, 'games_enabled', false),
      tests_enabled: field(brand, 'tests_enabled', false),
      articles_enabled: field(brand, 'articles_enabled', false),
      events_enabled: field(brand, 'events_enabled', false),
    };
  }

  getChangedValues(values: IOfferSettingsContentFormValues) {
    const formData = this.getFormData();
    if (formData) {
      return filterChangedValues(formData, values);
    }
    return values;
  }

  getSendindValues(values: IOfferSettingsContentFormValues): IOfferSettingsContentInput {
    const result: any = { ...values };
    const handle = (key: string, cb: (v: string) => number | null) => {
      if (key in values) {
        result[key] = cb(values[key] as string);
      }
    };
    [
      'audiobooks_max_count',
      'audiobooks_max_size',
      'books_download_limit',
      'books_max_size',
      'collections_max_count',
      'documents_max_count',
      'documents_max_size',
      'hybrids_max_count',
      'hybrids_max_size',
      'videos_max_count',
      'videos_max_size',
    ].forEach((key) => {
      handle(key, toInt);
    });
    return result;
  }

  onSubmit = (
    values: IOfferSettingsContentFormValues,
    formActions: FormikActions<IOfferSettingsContentFormValues>,
  ) => {
    let newFormData = { ...values };
    if (this.props.offer) {
      newFormData = this.getChangedValues(newFormData);
    }
    const settingsContent = this.getSendindValues(newFormData);
    const { id } = this.props.offer;
    const offer = { settingsContent };
    crudMutate({
      id,
      formActions,
      variables: { id, offer },
      mutation: editOfferQuery,
      check: !!Object.keys(settingsContent).length,
    });
  };

  render() {
    return (
      <Formik
        initialValues={this.getFormData()}
        validationSchema={this.getScheme()}
        enableReinitialize
        onSubmit={this.onSubmit}
      >
        {(props: FormikProps<IOfferSettingsContentFormValues>) => <BaseForm {...props} />}
      </Formik>
    );
  }
}

export default withTranslation('offers')(Form);
