import React, { ReactText, useState } from 'react';

import ReactSelect from 'features/ui/ReactSelect';
import { ISelectOption } from 'features/ui/ReactSelect/types';

interface IStoriesTypeSelectorProps {
  id: string;
  name: string;
  value: string;
  onBlur: () => void;
  onChange: (value: ReactText) => void;
}
const StoriesTypeSelector = ({
  id,
  name,
  value,
  onBlur,
  onChange,
}: IStoriesTypeSelectorProps) => {
  const [options] = useState<ISelectOption[]>([{
    value: 'big-square',
    label: 'Большой квадрат',
  }, {
    value: 'small-square',
    label: 'Маленький квадрат',
  }, {
    value: 'circle',
    label: 'Круг',
  }]);

  return (
    <ReactSelect
      async
      defaultOptions={options}
      id={id}
      defau
      isMulti={false}
      name={name}
      options={options}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      minSearchLength={1}
    />
  );
};

export default StoriesTypeSelector;
