import React from 'react';
import { Button, Input } from 'reactstrap';
import { gql } from 'apollo-boost';
import { client } from 'features/graphql';
import { toInteger } from 'lodash';
import styles from './styles.module.scss';

interface IItemImageUploaderProps {
  id: string;
  name: string;
  images: any;
  onChange?: (event: React.ChangeEvent<any>) => void;
  onBlur?: (event: React.FocusEvent<any>) => void;
}

interface IItemImageUploaderState {
  images: Array<{
    id: string;
    image: {
      id: string;
      path: string;
    };
  }>;
}

const UPLOAD_IMAGE_MUTATION = gql`
  mutation createItemMediaImage($itemMediaImage: ItemMediaImageInput) {
    ItemMediaImageMutation(itemMediaImage: $itemMediaImage) {
      id
      item {
        id
      }
      image {
        id
        path
      }
    }
  }
`;

const DELETE_IMAGE_MUTATION = gql`
  mutation deleteItemMediaImage($id: ID!) {
    ItemMediaImageMutation(id: $id, delete: true) {
      id
    }
  }
`;

class ItemImageUploader extends React.Component<IItemImageUploaderProps, IItemImageUploaderState> {
  constructor(props: IItemImageUploaderProps) {
    super(props);
    this.state = {
      images: props.images || [],
    };
  }

  onChangeImage = (imageEvent: React.ChangeEvent<any>) => {
    const { id } = this.props;
    imageEvent.persist();
    if (imageEvent.target.validity.valid) {
      const file = imageEvent.target.files[0];
      if (file) {
        client.mutate({
          mutation: UPLOAD_IMAGE_MUTATION,
          variables: {
            itemMediaImage: {
              item: toInteger(id),
              image: imageEvent.target.files[0],
            },
          },
        })
          .then((res) => {
            if (res) {
              const { id, image } = res.data.ItemMediaImageMutation;
              this.setState({
                images: [...this.state.images, { id, image }],
              });
            }
          });
      }
    }
  };

  onDelete = (id: string) => {;
    client.mutate({
      mutation: DELETE_IMAGE_MUTATION,
      variables: {
        id,
      },
    })
      .then(() => {
        this.setState({
          images: this.state.images.filter((image: any) => image.id !== id),
        });
      });
  }

  renderImages = () => {
    const { images } = this.state;
    if (images.length) {
      return images.map((media: any) => (
        <div className={styles.imageContainer}>
          <a href={media.image.path} target="_blank" rel="noopener noreferrer">
            <img className={styles.image} key={media.id} src={media.image.path} alt=""/>
          </a>
          <Button color="warning" type="button" onClick={() => this.onDelete(media.id)}>Удалить</Button>
        </div>

      ));
    }
    return null;
  }

  render() {
    const {
      id,
      name,
      onBlur,
    } = this.props;
    return (
      <>
        <Input
          type="file"
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={this.onChangeImage}
        />
        <div>
          <hr />
          {this.renderImages()}
        </div>
      </>
    );
  }
}

export default ItemImageUploader;
