import React from 'react';
import { withTranslation } from 'react-i18next';
import { set } from 'lodash';
import IndexTableBase, { IControlFields } from 'features/common/IndexTableBase';
import getItemsQuery from '../../queries/getItemsQuery.gql';
import resolveColumns from './columns';
import deleteItemMutation from 'features/items/queries/deleteItemMutation.gql';

import { Column } from 'features/types';
import { IItemsDataTableProps } from './types';

class ItemsDataTable extends React.PureComponent<IItemsDataTableProps> {
  columns: Column[];

  constructor(props: IItemsDataTableProps) {
    super(props);
    const columns = resolveColumns(props).filter(col => !!col) as Column[];
    this.columns = props.resolveColumns ? props.resolveColumns(columns) : columns;
  }

  prepareRequestData = (variables: any, fields: IControlFields) => {
    const { type } = this.props;
    if (fields.id) {
      variables.id = [fields.id];
    }
    if (type || fields.type) {
      set(variables, 'filters.type', { operator: '=', value: type || fields.type });
    }
    if (fields.is_active) {
      set(variables, 'filters.is_active', { operator: '=', value: fields.is_active });
    }
    if (fields.offers) {
      set(variables, 'filters.offers', { operator: '=', value: String(fields.offers) });
    }
    if (fields.categories) {
      set(variables, 'filters.categories', { operator: '=', value: fields.categories });
    }
    if (fields.badge) {
      set(variables, 'filters.badge', { operator: '=', value: fields.badge });
    }
    if (fields.creators) {
      set(variables, 'filters.creators', { operator: '=', value: fields.creators });
    }
    if (fields.name) {
      set(variables, 'filters.name', { operator: 'like', value: fields.name });
    }
    if (fields.publisher) {
      set(variables, 'filters.creators', { operator: 'like', value: fields.publisher });
    }
    if (fields.provider) {
      set(variables, 'searchByFields.provider', fields.provider);
    }
  };

  render() {
    const filterFields = [
      'badge',
      'categories',
      'creators',
      'id',
      'is_active',
      'name',
      'offers',
      'provider',
      'publisher',
      'type',
    ];
    return (
      <IndexTableBase
        columns={this.columns}
        filterFields={filterFields}
        sortFields={['name']}
        prepareRequestData={this.prepareRequestData}
        query={getItemsQuery}
        queryResultName="ItemQuery"
        deleteMutation={deleteItemMutation}
        showAutoEditIconColumn={false}
        showAutoIdColumn={false}
      />
    );
  }
}

export default withTranslation('items')(ItemsDataTable);
