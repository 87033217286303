import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { makeLinkListValue } from 'lib/makeLinkListValue';
import {
  InfoRowLinkList,
  InfoRowText,
} from 'features/ui/InfoRow';
import LinkBlock from 'features/ui/LinkBlock';
import { createView } from 'features/common';
import { makeOfferUrl } from 'features/offers/lib/url';
import {
  makeProductUrl,
  makeProductUrlTo,
} from '../lib/url';
import getEntity from '../queries/getProductQuery.gql';

import { PRODUCT_ICON } from '../consts';

import { IProduct } from '../types';

const View = createView<IProduct>(getEntity, 'ProductQuery');

class PageView extends React.PureComponent<WithTranslation> {
  renderChildren = (product: IProduct) => {
    const { t } = this.props;
    const offersLinkListValue = makeLinkListValue(product.offers ? product.offers : [], 'name', makeOfferUrl, 'view');
    return (
      <React.Fragment>
        <LinkBlock editLink={makeProductUrlTo(product, 'edit')} />
        <InfoRowText label="ID" value={product.id} />
        <InfoRowText label={t('name')} value={product.name} />
        <InfoRowLinkList
          label={t('offers:offers')}
          value={offersLinkListValue}
          byLine={true}
          max={10}
        />
        <hr />
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <View
        titleField="name"
        icon={PRODUCT_ICON}
        crumbs={[{ link: makeProductUrl(), name: t('products') }]}
        children={this.renderChildren}
      />
    );
  }
}

export default withTranslation('products')(PageView);
