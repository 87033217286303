import React from 'react';
import { Input } from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'Formik';
import TagsSelect, { IOnChangeInput } from 'features/tags/TagsSelect';
import CategoriesSelect from 'features/categories/lib/CategoriesSelect';
import OffersSelect from 'features/offers/lib/OffersSelect';
import BadgesSelect from 'features/badges/lib/BadgesSelect';
import LanguagesSelect from 'features/languages/LanguagesSelect';
import CreatorsSelect from 'features/creators/lib/CreatorsSelect';
import SubscriptionsSelect from 'features/subscriptions/lib/SubscriptionsSelect';
import ImageUploader from 'features/ui/ImageUploader';
import YearSelect from 'features/ui/YearSelect';
import DatePicker from 'features/ui/DatePicker';
import FixedOnTheRight from 'features/ui/FixedOnTheRightContainer';
import PricesEditWidget from 'features/prices/PricesEditWidget';
import FormFrame from 'features/ui/FormFrame';
import FormRow from 'features/ui/FormRow';
import Switch from 'features/ui/Switch';
import getOffersQuery from 'features/items/queries/getOffersQuery.gql';
import { client } from 'features/graphql';

import { IOfferQueryResponse } from 'features/offers/types';
import { IFormValues } from 'features/items/types';
import {
  IItem,
  Moment,
} from 'features/types';

import {
  TYPE_AUDIO,
  TYPE_COLLECTION,
  TYPE_VIDEO,
} from '../../consts';
import styles from './styles.module.scss';
import ItemsSelect from 'features/items/lib/ItemsSelect';
import MarkdownComponent from 'features/ui/MarkdownComponent';
import SetsSelect from 'features/blocks/lib/SetSelect';

interface IBaseFormProps extends FormikProps<IFormValues>, WithTranslation {
  cancelLink: string;
  type?: string;
  item?: IItem;
  resolveFields?: (fields: React.ReactNode[]) => React.ReactNode[];
}

class BaseForm extends React.PureComponent<IBaseFormProps> {
  getNameHint() {
    const {
      t,
      values,
      item,
    } = this.props;
    let total = 35;
    if (item) {
      if (item.type === TYPE_VIDEO) {
        total = 40;
      }
      return t('recommended_size', { total, amount: [...values.name].length });
    }
    return '';
  }

  onBlur = (field: string) => {
    this.props.setFieldTouched(field, true);
  };

  onChangeDate = (date: Moment | null) => {
    this.props.setFieldValue('copyright_expire', date ? date.format('YYYY-MM-DD') : null);
  };

  onChangeScope = (event: any) => {
    this.props.setFieldValue('scope', event.target.checked ? 'lab' : 'base');
  }

  onChangeImage = (e: React.ChangeEvent<HTMLInputElement> | null) => {
    if (e === null) {
      this.props.setFieldValue('image', null);
    } else {
      if (e.target.validity.valid) {
        this.props.setFieldValue('image', e.target.files![0]);
      }
    }
  };

  onChangeYear = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      this.props.setFieldValue('year', Number.parseInt(e.target.value, 10));
    }
  };

  onChangeEntity = (field: string) => (value: any) => {
    this.props.setFieldValue(field, value);
  };

  onChangeEntityId = (field: string) => (id: number) => {
    this.props.setFieldValue(field, id);
  };

  onChangeEntityIds = (field: string) => (ids: number[]) => {
    this.props.setFieldValue(field, ids);
  };

  onChangeTags = (values: IOnChangeInput) => {
    this.props.setFieldValue('stags', values.labels);
    this.props.setFieldValue('tags', values.ids);
  };

  onChangeExternalID = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.props.setFieldValue(field, String(value));
  }

  onClickOffers = (subVariables = {}) => {
    client.query<IOfferQueryResponse>({
      query: getOffersQuery,
      fetchPolicy: 'network-only',
      context: {
        isGlobalLoading: true,
      },
      variables: {
        page: 1,
        perPage: 5000,
        ...subVariables,
      },
    }).then((res) => {
      if (res.data && res.data.OfferQuery) {
        this.props.setFieldValue('offers', res.data.OfferQuery.items.map(offer => offer.id));
      }
    });
  };

  onClickAllOffers = () => {
    this.onClickOffers();
  };

  onClickNewItemsByDefault = () => {
    this.onClickOffers({
      filterByFields: {
        new_items_by_default: {
          operator: '=',
          value: '1',
        },
      },
    });
  };

  renderOffers() {
    const {
      t,
      values,
    } = this.props;
    const side = (
      <React.Fragment>
        <a
          className="btn btn-warning"
          onClick={this.onClickAllOffers}
          title="Добавляет все Предложения"
        >
          Добавить Все
        </a>
        <a
          className="btn btn-warning"
          onClick={this.onClickNewItemsByDefault}
          title={`Добавляет Предложения с включенным флагом «${t('offers:offer_new_items_by_default')}»`}
        >
          Новые элементы
        </a>
      </React.Fragment>
    );
    return (
      <FormRow key="offers" label={t('offers')} id="offers">
        <FixedOnTheRight side={side}>
          <div>
            <OffersSelect
              id="offers"
              name="offers"
              value={values.offers}
              isClearable={true}
              onChange={this.onChangeEntityIds('offers')}
              onBlur={this.onBlur.bind(this, 'offers')}
            />
          </div>
        </FixedOnTheRight>
      </FormRow>
    );
  }

  parseImagesLinks(images: []) {
    return images.map((image: any, index: number) => (
      <p key={index} className={styles.imageLinks}>
        <a href={image.path} target="_blank">
          {image.height}x{image.width}
        </a>
      </p>
    ));
  }

  renderImage() {
    const {
      t,
      type,
      values,
      handleBlur,
    } = this.props;
    let text = '';
    switch (type) {
      case TYPE_COLLECTION: text = t('forms:expected_size', { width: 820, height: 460 }); break;
      case TYPE_AUDIO: text = t('forms:expected_size', { width: 1024, height: 1024 }); break;
      case TYPE_VIDEO: text = t('forms:expected_size', { width: 1024, height: 570 }); break;
      default: text = t('forms:expected_width', { width: 1024 }); break;
    }

    return (
      <FormRow
        key="image"
        label={t('image')}
        id="image"
        text={text}
        required
      >
        <ImageUploader
          id="image"
          name="image"
          value={values.image}
          onChange={this.onChangeImage}
          onBlur={handleBlur}
          imageWidth={256}
        />
        {values.images && values.images.length ? this.parseImagesLinks(values.images) : null}
      </FormRow>
    );
  }

  renderFields = () => {
    const {
      t,
      item,
      values,
      handleBlur,
      handleChange,
      resolveFields,
    } = this.props;

    const fields = [(
      <FormRow key="is_active" label="Активно?" id="is_active">
        <Switch
          id="is_active"
          name="is_active"
          offColor="danger"
          checked={values.is_active}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    ), (
      <FormRow key="is_public" label="Публичный итем?" id="is_public">
        <Switch
          id="is_public"
          name="is_public"
          offColor="danger"
          checked={values.is_public}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    ), (
        <FormRow key="scope" label="Контент Альпина.Лаб" id="scope">
          <Switch
            id="scope"
            name="scope"
            checked={values.scope === 'lab'}
            onChange={this.onChangeScope}
            onBlur={handleBlur}
          />
        </FormRow>
    ), (
      <FormRow key="is_offer_specific" label={t('is_offer_specific')} id="is_offer_specific">
        <Switch
          id="is_offer_specific"
          name="is_offer_specific"
          checked={values.is_offer_specific}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    ), (
      <FormRow key="is_hidden" label={t('is_hidden')} id="is_hidden">
        <Switch
          id="is_hidden"
          name="is_hidden"
          checked={values.is_hidden}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    ), (
      <FormRow
        key="name"
        label={t('name')}
        id="name"
        text={this.getNameHint()}
        required
      >
        <Input
          type="text"
          id="name"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    ), (
      <FormRow
        key="original_name"
        label={t('original_name')}
        id="original_name"
      >
        <Input
          type="text"
          id="original_name"
          name="original_name"
          value={values.original_name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    ), (
      // *** Item.image ***
      this.renderImage()
    ), (
      <FormRow key="copyright_expire" label={t('copyright_expire')} id="copyright_expire">
        <DatePicker
          id="copyright_expire"
          value={Date.parse(values.copyright_expire)}
          onChange={this.onChangeDate}
        />
      </FormRow>
    ), (
      <FormRow id="age_limit" label={t('age_limit')} text={`Будет отображаться как: ${values.age_limit}+`}>
        <Input
          type="number"
          id="age_limit"
          name="age_limit"
          value={values.age_limit}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    ), (
      // *** Item.offers ***
      this.renderOffers()
    ), (
      <FormRow key="relatedSecondaryItems" label={t('relatedSecondaryItems')} id="relatedSecondaryItems">
        <ItemsSelect
          id="relatedSecondaryItems"
          name="relatedSecondaryItems"
          value={values.relatedSecondaryItems}
          itemId={item ? item.id : ''}
          isClearable={true}
          isMulti
          onChange={this.onChangeEntityIds('relatedSecondaryItems')}
          onBlur={this.onBlur.bind(this, 'relatedSecondaryItems')}
        />
      </FormRow>
    ), (
      <FormRow key="relatedSets" label={t('relatedSets')} id="relatedSets">
        <SetsSelect
          id="relatedSets"
          name="relatedSets"
          value={values.relatedSets}
          isClearable={true}
          isMulti
          onChange={this.onChangeEntityIds('relatedSets')}
          onBlur={this.onBlur.bind(this, 'relatedSets')}
        />
      </FormRow>
    ), (
      <FormRow key="recommendedSecondaryItems" label={t('recommendedSecondaryItems')} id="recommendedSecondaryItems">
        <ItemsSelect
          id="recommendedSecondaryItems"
          name="recommendedSecondaryItems"
          value={values.recommendedSecondaryItems}
          itemId={item ? item.id : ''}
          isClearable={true}
          isMulti
          onChange={this.onChangeEntityIds('recommendedSecondaryItems')}
          onBlur={this.onBlur.bind(this, 'recommendedSecondaryItems')}
        />
      </FormRow>
    ), (
      <FormRow
        key="recommendation_description"
        label={t('recommendation_description')}
        id="recommendation_description"
      >
        <MarkdownComponent
          onChange={this.onChangeEntity('recommendation_description')}
          value={values.recommendation_description}
        />
      </FormRow>
    ), (
      <FormRow key="categories" label={t('categories')} id="categories">
        <CategoriesSelect
          id="categories"
          name="categories"
          isMulti
          value={values.categories}
          onChange={this.onChangeEntityIds('categories')}
          onBlur={this.onBlur.bind(this, 'categories')}
        />
      </FormRow>
    ),(
      <FormRow key="subscriptions" label={t('subscriptions')} id="subscriptions">
        <SubscriptionsSelect
          id="subscriptions"
          name="subscriptions"
          isMulti
          value={values.subscriptions}
          onChange={this.onChangeEntityIds('subscriptions')}
          onBlur={this.onBlur.bind(this, 'subscriptions')}
        />
      </FormRow>
    ), (
      <FormRow key="language" label={t('language')} id="language" required>
        <LanguagesSelect
          id="language"
          name="language"
          value={values.language}
          onChange={this.onChangeEntityId('language')}
          onBlur={this.onBlur.bind(this, 'language')}
        />
      </FormRow>
    ), (
      <FormRow key="provider" label={t('provider')} id="provider">
        <CreatorsSelect
          type="provider"
          id="provider"
          name="provider"
          value={values.provider}
          onChange={this.onChangeEntityId('provider')}
          onBlur={this.onBlur.bind(this, 'provider')}
        />
      </FormRow>
    ), (
      <FormRow key="publisher" label={t('publisher')} id="publisher">
        <CreatorsSelect
          type="publisher"
          id="publisher"
          name="publisher"
          value={values.publisher}
          onChange={this.onChangeEntityId('publisher')}
          onBlur={this.onBlur.bind(this, 'publisher')}
        />
      </FormRow>
    ), (
      <FormRow key="authors" label="Авторы" id="authors">
        <CreatorsSelect
          type="author"
          id="authors"
          name="authors"
          value={values.authors}
          onChange={this.onChangeEntityIds('authors')}
          onBlur={this.onBlur.bind(this, 'authors')}
        />
      </FormRow>
    ), (
      <FormRow key="tags" label={t('tags')} id="tags">
        <TagsSelect
          id="tags"
          name="tags"
          value={values.tags}
          onChange={this.onChangeTags}
          onBlur={this.onBlur.bind(this, 'tags')}
        />
      </FormRow>
    ), (
      <FormRow key="description" label={t('description')} id="description">
        <Input
          type="textarea"
          name="description"
          id="description"
          rows="7"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    ), (
      <FormRow key="badge" label={t('badge')} id="badge">
        <BadgesSelect
          id="badge"
          name="badge"
          value={values.badge}
          onChange={this.onChangeEntityId('badge')}
          onBlur={this.onBlur.bind(this, 'badge')}
        />
      </FormRow>
    ), (
      <FormRow key="year" label={t('year')} id="year">
        <YearSelect
          name="year"
          value={values.year || (!item ? (new Date()).getFullYear() + 1 : 0)}
          onChange={this.onChangeYear}
          onBlur={handleBlur}
        />
      </FormRow>
    ), (
      <FormRow key="bisac" label={t('bisac')} id="bisac" text="Пример: MAT033000">
        <Input
          type="text"
          id="bisac"
          name="bisac"
          placeholder="BISAC"
          value={values.bisac}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    ), (
      <FormRow key="external_link" label={t('external_link')} id="external_link" text="Пример: https://example.com">
        <Input
          type="text"
          id="external_link"
          name="external_link"
          placeholder="ИМАГ URL"
          value={values.external_link}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    ), (
      <FormRow key="external_id" label={t('external_id')} id="external_id" text="Пример: 12345">
        <Input
          type="text"
          id="external_id"
          name="external_id"
          value={values.external_id}
          onChange={this.onChangeExternalID('external_id')}
          onBlur={handleBlur}
        />
      </FormRow>
    ), (
      <FormRow key="prices" label={t('prices')} id="prices" text={!values.prices.length ? 'Введите ISBN для автогенерации цен' : ''}>
        <PricesEditWidget
          prices={values.prices}
          onChange={this.onChangeEntity('prices')}
        />
      </FormRow>
    ), (
      <FormRow
        key="position_latest"
        label={t('position_latest')}
        id="position_latest"
      >
        <Input
          type="number"
          id="position_latest"
          name="position_latest"
          value={values.position_latest}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    ), (
      <FormRow
        key="position_bestsellers"
        label={t('position_bestsellers')}
        id="position_bestsellers"
      >
        <Input
          type="number"
          id="position_bestsellers"
          name="position_bestsellers"
          value={values.position_bestsellers}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    ),
    ];

    return resolveFields ? resolveFields(fields) : fields;
  };

  render() {
    const {
      cancelLink,
      item,
    } = this.props;

    return (
      <FormFrame
        id={item && item.id}
        cancelLink={cancelLink}
        key={item && item.id}
        {...this.props}
      >
        {this.renderFields()}
      </FormFrame>
    );
  }
}

export default withTranslation('items')(BaseForm);
