import React from 'react';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { crudMutate } from 'features/common/helpers';
import {
  imageFileValidator,
  stringValidator,
  weightValidator,
} from 'lib/validators';
import filterChangedValues from 'lib/filterChangedValues';
import field from 'lib/field';
import BaseBannerForm from './BaseBannerForm';
import { makeBannerUrl } from '../url';
import createBannerMutation from '../../queries/createBanner.gql';
import editBannerMutation from '../../queries/editBanner.gql';
import {
  IBanner,
  IBannerFormValues,
} from '../../types';

import * as Yup from 'yup';

interface IBadgeFormProps extends WithTranslation {
  banner?: IBanner;
  isCreateForm?: boolean;
}

class BannerForm extends React.Component<IBadgeFormProps> {
  getScheme() {
    const { t} = this.props;
    const required = true;
    const validators: any = {
      name: stringValidator({ required, field: t('name') }),
      weight: weightValidator({ required, field: t('weight') }),
      resource_link: stringValidator({ required, field: t('resource_link') }),
    };
    return Yup.object().shape(validators);
  }

  getChangedValues(values: IBannerFormValues) {
    const formData = this.buildFormData();
    return filterChangedValues(formData, values);
  }

  onSubmit = (
    values: IBannerFormValues,
    formActions: FormikActions<IBannerFormValues>,
  ) => {
    let banner = { ...values };
    let id;
    if (this.props.banner) {
      const data = this.buildFormData();
      banner = this.getChangedValues(values);
      id = this.props.banner.id;
      if (JSON.stringify(banner.image) === JSON.stringify(data.image)) {
        delete banner.image;
      }
      if (JSON.stringify(banner.image_dark) === JSON.stringify(data.image_dark)) {
        delete banner.image_dark;
      }
    }
    if (banner.theme) {
      delete banner.theme;
    }
    if (banner.image_dark && banner.image_dark.theme && !banner.image_dark.name) {
      delete banner.image_dark;
    }
    crudMutate({
      id,
      formActions,
      variables: id ? { id, banner } : { banner },
      mutation: id ? editBannerMutation : createBannerMutation,
      redirect: makeBannerUrl(),
      check: !!Object.keys(banner).length,
    });
  };

  getImagesByTheme(images, theme) {
    return images.filter(image => image.theme === theme);
  }

  getFirstImageByTheme(images, theme) {
    const filteredImages = this.getImagesByTheme(images, theme);
    return filteredImages.length > 0 ? filteredImages[0] : null;
  }

  buildFormData(): IBannerFormValues {
    const { banner } = this.props;
    const images = banner && banner.images;
    const lightImage = images ? this.getFirstImageByTheme(images, 'light') : { theme: 'light' };
    const darkImage = images ? this.getFirstImageByTheme(images, 'dark') : { theme: 'dark' };

    return {
      name: field(banner, 'name', ''),
      is_active: field(banner, 'is_active', false),
      resource_type: field(banner, 'resource_type', 'category'),
      resource_link: field(banner, 'resource_link', ''),
      weight: field(banner, 'weight', 0),
      creators: banner ? banner.creators.map(c => parseInt(c.id, 10)) : [],
      categories: banner ? banner.categories.map(c => parseInt(c.id, 10)) : [],
      image: lightImage,
      image_dark: darkImage,
      offers: banner ? banner.offers.map(o => o.id) : [],
      started_at: field(banner, 'started_at', null),
      finished_at: field(banner, 'finished_at', null),
      format: field(banner, 'format', 'standard'),
      theme: field(banner, 'theme', 'light'),
    };
  }

  render() {
    const { banner } = this.props;
    return (
      <Formik
        initialValues={this.buildFormData()}
        onSubmit={this.onSubmit}
        validationSchema={this.getScheme()}
      >
        {(props: FormikProps<IBannerFormValues>) => <BaseBannerForm {...props} banner={banner} />}
      </Formik>
    );
  }
}

export default withTranslation('banners')(BannerForm);
