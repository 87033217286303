import React from 'react';
import {
  Col,
  Container,
  Row,
} from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import FileInfoLink from 'features/ui/FileInfoLink';
import Input from 'features/ui/Input';

import styles from './styles.module.scss';

import { IItemContent } from 'features/types';

interface IAudiobooksList extends WithTranslation {
  list: IItemContent[];
}

export default withTranslation('audiobooks')(
  ({ t, list }: IAudiobooksList ) => {
    if (list.length) {
      const listOfFiles = list.map(
        ({ id, title, sort, file, is_preview }, order: number) => {
          return (
            <Container key={id} className={styles.fileListItem}>
              <Row>
                <Col xs={1}>{sort}</Col>
                <Col xs={9}>{title}</Col>
                <Col xs={2}>
                  <label htmlFor="is_preview" className={styles.previewWrap}>
                    <p>{t('is_preview')}</p>
                    <Input
                      id="is_preview"
                      name="is_preview"
                      type="checkbox"
                      checked={is_preview}
                      disabled
                    />
                  </label>
                </Col>
              </Row>
              <Row>
                <Col xs={1}>
                  &nbsp;
                </Col>
                <Col xs={11}>
                  <FileInfoLink
                    name={t('content')}
                    file={file}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={1}>
                  &nbsp;
                </Col>
                <Col xs={11}>
                  <audio
                    src={file.path}
                    controls
                    className={styles.fileInfoLink}
                  />
                </Col>
              </Row>
            </Container>
          );
        },
      );
      return (
        <React.Fragment>
          {listOfFiles}
        </React.Fragment>
      );
    }
    return null;
  },
);
