import React from 'react';
import {Button, Input} from 'reactstrap';
import { gql } from 'apollo-boost';
import { client } from 'features/graphql';
import { toInteger } from 'lodash';
import styles from './styles.module.scss';

interface IItemFileUploaderProps {
  id: string;
  name: string;
  files: any;
  onChange?: (event: React.ChangeEvent<any>) => void;
  onBlur?: (event: React.FocusEvent<any>) => void;
}

interface IItemFileUploaderState {
  files: Array<{
    id: string;
    file: {
      id: string;
      path: string;
    };
  }>;
}

const UPLOAD_FILE_MUTATION = gql`
  mutation createItemMediaFile($itemMediaFile: ItemMediaFileInput) {
    ItemMediaFileMutation(itemMediaFile: $itemMediaFile) {
      id
      item {
        id
      }
      file {
        id
        path
      }
    }
  }
`;

const DELETE_FILE_MUTATION = gql`
  mutation deleteItemMediaFile($id: ID!) {
    ItemMediaFileMutation(id: $id, delete: true) {
      id
    }
  }
`

class ItemImageUploader extends React.Component<IItemFileUploaderProps, IItemFileUploaderState> {
  constructor(props: IItemFileUploaderProps) {
    super(props);
    this.state = {
      files: props.files || [],
    };
  }

  onChangeImage = (imageEvent: React.ChangeEvent<any>) => {
    const { id } = this.props;
    imageEvent.persist();
    if (imageEvent.target.validity.valid) {
      const file = imageEvent.target.files[0];
      if (file) {
        client.mutate({
          mutation: UPLOAD_FILE_MUTATION,
          variables: {
            itemMediaFile: {
              item: toInteger(id),
              file: imageEvent.target.files[0],
            },
          },
        })
          .then((res) => {
            if (res) {
              const { id, file } = res.data.ItemMediaFileMutation;
              this.setState({
                files: [...this.state.files, { id, file }],
              });
            }
          });
      }
    }
  };

  onDelete = (id: string) => {
    client.mutate({
      mutation: DELETE_FILE_MUTATION,
      variables: {
        id,
      },
    }).then(() => {
      this.setState({
        files: this.state.files.filter((media: any) => media.id !== id),
      });
    });
  }

  renderFiles = () => {
    const { files } = this.state;
    if (files.length) {
      return files.map((media: any) => (
        <div className={styles.urlContainer}>
          <a href={media.file.path} target="_blank" rel="noopener noreferrer">
            <p style={{margin: 8}} key={media.id}>{media.file.path}</p>
          </a>
          <Button color="warning" type="button" onClick={() => this.onDelete(media.id)}>Удалить</Button>
        </div>
      ));
    }
    return null;
  }

  render() {
    const {
      id,
      name,
      onBlur,
    } = this.props;
    return (
      <>
        <Input
          type="file"
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={this.onChangeImage}
        />
        <div>
          <hr />
          {this.renderFiles()}
        </div>
      </>
    );
  }
}

export default ItemImageUploader;
