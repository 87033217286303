import React from 'react';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import { get } from 'lodash';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { minValidator } from 'lib/validators';
import field from 'lib/field';
import { contentValidator } from 'features/items/lib/validators';
import ItemForm from 'features/items/lib/Form';
import BaseForm from './Form';

import getAudiobookQuery from 'features/audiobooks/queries/getAudiobookQuery.gql';

import {
  IItem,
  IItemFormValues,
} from 'features/types';

interface IFormProps extends WithTranslation {
  data?: IItem;
}

class Form extends React.PureComponent<IFormProps> {
  resolveMutationData = (values: any) => {
    return values;
  };

  renderForm = (props: FormikProps<IItemFormValues>) => (<BaseForm {...props} item={this.props.data} />);

  validationSchema = (shape: any) => {
    const {
      t,
      data,
    } = this.props;
    const isEdit = !!(data && data.id);
    return {
      ...shape,
      content: contentValidator(t, isEdit, {
        ext: '*.zip',
        formats: ['application/zip', 'application/x-zip-compressed'],
      }),
      audiobook: Yup.object().shape({
        duration: minValidator({
          field: t('duration'),
          min: 0,
          required: true,
        }),
      }),
    };
  };

  resolveInitialValues = (values: IItemFormValues): IItemFormValues => {
    const { data } = this.props;
    const hasContent = data && data.content && Array.isArray(data.content);
    return {
      ...values,
      audiobook: {
        isbn: field(data, 'audiobook.isbn', ''),
        duration: field(data, 'audiobook.duration', 0),
        type: field(data, 'audiobook.type', 'full'),
      },
      content: hasContent ?
        data!.content
          .map(({ id, title, sort, is_preview }) => ({ id, title, sort, is_preview }))
          .sort((a, b) => a.sort - b.sort)
        : [],
    };
  };

  resolveChangedValues = (data: IItemFormValues): IItemFormValues => {
    const { content } = data;
    if (content) {
      const filteredContent = content.filter((c, id) => {
        return (
          c.title !== get(this.props.data, `content[${id}].title`, false) ||
          c.sort !== get(this.props.data, `content[${id}].sort`, false) ||
          c.is_preview !== get(this.props.data, `content[${id}].is_preview`, false)
        );
      });
      if (filteredContent.length) {
        data.content = filteredContent;
      } else {
        delete data.content;
      }
    }
    return data;
  }

  render() {
    const {
      data,
    } = this.props;

    return (
      <ItemForm
        type="audiobook"
        data={data}
        getItemQuery={getAudiobookQuery}
        resolveValidationSchema={this.validationSchema}
        resolveInitialValues={this.resolveInitialValues}
        resolveMutationData={this.resolveMutationData}
        resolveChangedValues={this.resolveChangedValues}
        renderForm={this.renderForm}
      />
    );
  }
}

export default withTranslation('audiobooks')(Form);
