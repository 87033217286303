import i18n from 'features/intl/i18n';
import { filterInputHandler } from 'features/common';

export default () => [
  {
    Header: i18n.t('series:name'),
    accessor: 'name',
    minWidth: 100,
    Filter: filterInputHandler,
  },
];
