import React from 'react';
import { Input } from 'reactstrap';
import i18n from 'features/intl/i18n';
import {
  TYPE_AUTHOR,
  TYPE_LECTURER,
  TYPE_NARRATOR,
  TYPE_PROVIDER,
  TYPE_PUBLISHER,
  TYPE_SPEAKER,
  TYPE_TRANSLATOR,
} from 'features/creators/consts';

import { ISelectOption } from 'features/types';

const types = [
  { value: TYPE_AUTHOR, label: i18n.t('creators:types.author') },
  { value: TYPE_PUBLISHER, label: i18n.t('creators:types.publisher') },
  { value: TYPE_LECTURER, label: i18n.t('creators:types.lecturer') },
  { value: TYPE_PROVIDER, label: i18n.t('creators:types.provider') },
  { value: TYPE_NARRATOR, label: i18n.t('creators:types.narrator') },
  { value: TYPE_SPEAKER, label: i18n.t('creators:types.speaker') },
  { value: TYPE_TRANSLATOR, label: i18n.t('creators:types.translator') },
];

interface ICreatorsTypeSelectProps {
  id?: string;
  name?: string;
  value: string | number;
  emptyOption?: ISelectOption;
  onChange: any;
  onBlur?: any;
}

const CreatorsTypeSelect = (props: ICreatorsTypeSelectProps) => {
  const {
    id,
    value,
    name,
    emptyOption,
    onChange,
    onBlur,
  } = props;
  const options = types
    .map(type => (
      <option key={type.label} value={type.value}>
        {type.label}
      </option>
    ));
  return (
    <Input
      type="select"
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      {emptyOption ?  <option value={emptyOption.value}>{emptyOption.label}</option> : null}
      {options}
    </Input>
  );
};

export default CreatorsTypeSelect;
