import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'formik';
import FormFrame from 'features/ui/FormFrame';
import { formFieldsBuilder } from 'features/ui/FormFields';
import { IOfferSettingsContentFormValues } from '../types';

interface IFormProps extends FormikProps<IOfferSettingsContentFormValues>, WithTranslation {
  id?: string;
}

const Brand = formFieldsBuilder({ labelPrefix: 'content_' });

class BaseForm extends React.Component<IFormProps> {
  render() {
    const {
      id,
    } = this.props;
    const form = this.props;
    return (
      <FormFrame
        id={id}
        cancelLink={'/offers'}
        {...this.props}
      >
        <Brand.Input name="books_download_limit" type="number" form={form} hasSwitcher />
        <Brand.Switch name="events_calendar_enabled" form={form} />
        <hr />
        <Brand.Switch name="latest_enabled" form={form} />
        <Brand.Switch name="popular_enabled" form={form} />
        <hr />
        <Brand.Switch name="books_enabled" form={form} />
        <Brand.Bytes name="books_max_size" form={form} hasSwitcher />
        <Brand.Switch name="books_download_epub_enabled" form={form} />
        <Brand.Switch name="books_download_pdf_enabled" form={form} />
        <Brand.Switch name="books_download_mobi_enabled" form={form} />
        <hr />
        <Brand.Switch name="paperbooks_enabled" form={form} />
        <hr />
        <Brand.Switch name="hybrids_enabled" form={form} />
        <Brand.Input name="hybrids_max_count" type="number" form={form} hasSwitcher />
        <Brand.Bytes name="hybrids_max_size" type="number" form={form} hasSwitcher />
        <hr />
        <Brand.Switch name="documents_enabled" form={form} />
        <Brand.Input name="documents_max_count" type="number" form={form} hasSwitcher />
        <Brand.Bytes name="documents_max_size" type="number" form={form} hasSwitcher />
        <hr />
        <Brand.Switch name="collections_enabled" form={form} />
        <Brand.Input name="collections_max_count" type="number" form={form} hasSwitcher />
        <hr />
        <Brand.Switch name="videos_enabled" form={form} />
        <Brand.Input name="videos_max_count" type="number" form={form} hasSwitcher />
        <Brand.Bytes name="videos_max_size" type="number" form={form} hasSwitcher />
        <hr />
        <Brand.Switch name="courses_enabled" form={form} />
        <Brand.Switch name="articles_enabled" form={form} />
        <hr />
        <Brand.Switch name="audiobooks_enabled" form={form} />
        <Brand.Input name="audiobooks_max_count" type="number" form={form} hasSwitcher />
        <Brand.Bytes name="audiobooks_max_size" type="number" form={form} hasSwitcher />
        <hr />
        <Brand.Switch name="games_enabled" form={form} />
        <Brand.Switch name="tests_enabled" form={form} />
        <hr />
        <Brand.Switch name="events_enabled" form={form} />
      </FormFrame>
    );
  }
}

export default withTranslation('offers')(BaseForm);
