import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';
import AppRoute from 'features/app/Route';
import PageSeries from '../PageSeries';
import PageEdit from '../PageEdit';
import PageView from '../PageView';
import PageCreate from '../PageCreate';

export default () => (
  <AccessControl permission="series:access">
    <Switch>
      <AppRoute path="/series" menuId="series" component={PageSeries} exact />
      <AppRoute path="/serie/create" menuId="series" component={PageCreate} exact />
      <AppRoute path="/serie/edit/:id" menuId="series" component={PageEdit} exact />
      <AppRoute path="/serie/:id" menuId="series" component={PageView} exact />
      <Route name="404" component={Page404} />
    </Switch>
  </AccessControl>
);
