import React from 'react';
import { Input } from 'reactstrap';

import {
  TYPE_ARTICLE,
  TYPE_AUDIO,
  TYPE_BOOK,
  TYPE_COLLECTION,
  TYPE_COURSE,
  TYPE_DOC,
  TYPE_EVENT,
  TYPE_HYBRID,
  TYPE_TEST,
  TYPE_VIDEO,
} from 'features/items/consts';

interface IItemTypeSelectProps {
  onChange: any;
  value: any;
}

const types: { [type: string]: string } = {
  [TYPE_BOOK]: 'Книги',
  [TYPE_HYBRID]: 'Гибриды',
  [TYPE_DOC]: 'Документы',
  [TYPE_AUDIO]: 'Аудиокниги',
  [TYPE_VIDEO]: 'Видео',
  [TYPE_COLLECTION]: 'Коллекции',
  [TYPE_EVENT]: 'Мероприятия',
  [TYPE_COURSE]: 'Курсы',
  [TYPE_TEST]: 'Тесты',
  [TYPE_ARTICLE]: 'Статьи',
};

const ItemTypeSelect = (props: IItemTypeSelectProps) => {
  const {
    value,
    onChange,
  } = props;
  const options = Object
    .keys(types)
    .map((type: string) => (
      <option
        key={type}
        value={type}
      >
        {types[type]}
      </option>
    ));
  return (
    <Input
      type="select"
      onChange={onChange}
      value={value}
    >
      <option value="">-</option>
      {options}
    </Input>
  );
};

export default ItemTypeSelect;
