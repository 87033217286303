import {
  graphql,
  OptionProps,
} from 'react-apollo';
import { gql } from 'features/graphql';
import {
  IListQueryVariables,
  ISharedSelectProps,
} from 'features/types';
import { ILayoutResponse } from 'features/layouts/types';

const QUERY = gql`
  query LayoutsQuery(
    $id: [ID]
    $page: Int
    $perPage: Int
    $filterByFields: LayoutFilterByFields
  ) {
    LayoutQuery(
      id: $id
      page: $page
      perPage: $perPage
      filterByFields: $filterByFields
    ) {
      items {
        id
        name
      }
    }
  }
`;

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, ILayoutResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;

  return {
    data: data && data.LayoutQuery ? data.LayoutQuery.items : [],
    ...ownProps,
  };
};

export default graphql<ISharedSelectProps, ILayoutResponse, IListQueryVariables, any>(QUERY, {
  props: mapResultsToProps,
  options: () => ({
    variables: {
      pageNum: 1,
      perPage: 1000,
    },
  }),
});
