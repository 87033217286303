import React from 'react';
import { Input } from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'formik';
import FormFrame from 'features/ui/FormFrame';
import FormRow from 'features/ui/FormRow';
import ImageUploader from 'features/ui/ImageUploader';
import { InfoRow } from 'features/ui/InfoRow';
import ItemsIdsTextarea from 'features/items/lib/ItemsIdsTextarea';
import LinkToOffer from 'features/offers/lib/LinkToOffer';
import ColorPicker from 'features/ui/ColorPicker';
import LinkToItems from '../LinkToItems';
import { makeBadgeUrl } from '../url';
import getBadgeQuery from '../../queries/getBadge.gql';
import editBadgeMutation from '../../queries/editBadge.gql';
import {
  IBadge,
  IBadgeFormValues,
} from '../../types';

interface IBadgeFormProps extends FormikProps<IBadgeFormValues>, WithTranslation {
  badge?: IBadge;
}

class BaseBadgeForm extends React.Component<IBadgeFormProps> {
  onChangeImage = (e: any) => {
    if (e.target.validity.valid) {
      this.props.handleChange(e);
      this.props.setFieldValue('image', e.target.files[0]);
    }
  };

  onChangeColor = (color: string) => {
    const { setFieldValue } = this.props;
    setFieldValue('color', color);
  };

  renderOwnerOffer() {
    const {
      t,
      badge,
    } = this.props;
    if (!badge) {
      return null;
    }
    if (badge.ownerOffer) {
      return (
        <InfoRow label={t('ownerOffer')}>
          <LinkToOffer
            value={badge.ownerOffer}
            action="view"
          />
        </InfoRow>
      );
    }
    return <InfoRow label={t('ownerOffer')}>{t('ownerOfferAdmin')}</InfoRow>;
  }

  renderItems() {
    const { badge } = this.props;
    if (badge && badge.id) {
      return (
        <React.Fragment>
          <FormRow
            label="Добавить/Удалить итемы"
            id="ItemsIdsTextarea"
          >
            <ItemsIdsTextarea
              mutation={editBadgeMutation}
              query={getBadgeQuery}
              data={badge}
              entityName="badge"
              fieldName="items"
            />
          </FormRow>
        </React.Fragment>
      );
    }
    return null;
  }

  renderLinkToItems() {
    const {
      t,
      badge,
    } = this.props;
    if (badge) {
      return (
        <InfoRow label={t('items:items')}>
          <LinkToItems badge={badge} />
        </InfoRow>
      );
    }
    return null;
  }

  render() {
    const {
      badge,
      handleBlur,
      handleChange,
      t,
      values,
    } = this.props;
    return (
      <FormFrame
        id={badge ? badge.id : undefined}
        cancelLink={makeBadgeUrl()}
        {...this.props}
      >
        <FormRow label={t('name')} id="name" required>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder={t('name')}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow
          label={t('image')}
          id="image"
          text={t('forms:expected_size', { width: 85, height: 85 })}
          required
        >
          <ImageUploader
            id="image"
            name="image"
            value={values.image}
            onChange={this.onChangeImage}
            onBlur={handleBlur}
            imageWidth={85}
            imageHeight={85}
          />
        </FormRow>

        <FormRow label={t('color')} id="color" text={t('forms:example', { text: '#3a4b5c' })}>
          <ColorPicker
            id="color"
            name="color"
            placeholder={t('color')}
            color={values.color}
            onChange={this.onChangeColor}
          />
        </FormRow>

        <FormRow label={t('key')} id="key">
          <Input
            type="text"
            id="key"
            name="key"
            placeholder={t('key')}
            value={values.key}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        {this.renderOwnerOffer()}
        {this.renderLinkToItems()}
        <hr />
        {this.renderItems()}
      </FormFrame>
    );
  }
}

export default withTranslation('badges')(BaseBadgeForm);
