import React from 'react';
import {
  get,
} from 'lodash';
import { DocumentNode } from 'graphql';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import field from 'lib/field';
import filterChangedValues from 'lib/filterChangedValues';
import { stringValidator } from 'lib/validators';
import { crudMutate } from 'features/common/helpers';
import * as Yup from 'yup';
import { makeLayoutUrl } from '../url';
import BaseLayoutForm from './BaseLayoutForm';

import {
  ILayout,
  ILayoutFormValues,
} from '../../types';

interface ICategoryFormProps extends WithTranslation {
  layout?: ILayout;
  mutation: DocumentNode;
}

class LayoutForm extends React.Component<ICategoryFormProps> {
  getValidationScheme() {
    const { t } = this.props;
    const required = true;
    const validators: any = {
      name: stringValidator({ required, field: t('name') }),
    };
    return Yup.object().shape(validators);
  }

  getChangedValues(values: ILayoutFormValues) {
    const formData = this.buildFormData();
    if (formData) {
      return filterChangedValues(formData, values);
    }
    return values;
  }

  onSubmit = (
    formData: ILayoutFormValues,
    formActions: FormikActions<ILayoutFormValues>,
  ) => {
    const { mutation } = this.props;
    const id = get(this.props, 'layout.id', undefined);
    const decorated = {
      ...formData,
      blocks: formData.blocks ? formData.blocks.map((block, index) => ({ id: block.id, sort: index })) : [],
    };
    delete decorated.template;

    const variables = (id ? { id, layout: this.getChangedValues(decorated) } : { layout: { ...decorated } });
    crudMutate({
      id,
      formActions,
      variables,
      mutation,
      redirect: makeLayoutUrl(),
      check: !!Object.keys(variables.layout).length,
    });
  };

  buildFormData(): ILayoutFormValues {
    const { layout } = this.props;
    return {
      name: field(layout, 'name', ''),
      offers: layout ? layout.offers.map(o => o.id) : [],
      blocks: layout ? layout.blocks.sort((a, b) => a.sort - b.sort) : [],
      template: 0,
    };
  }

  renderForm = (props: FormikProps<ILayoutFormValues>) => {
    const { layout } = this.props;
    return (
      <BaseLayoutForm
        {...props}
        layout={layout}
      />
    );
  };

  render() {
    return (
      <Formik
        // enableReinitialize
        initialValues={this.buildFormData()}
        onSubmit={this.onSubmit}
        validationSchema={this.getValidationScheme()}
        render={this.renderForm}
      />
    );
  }
}

export default withTranslation('layouts')(LayoutForm);
