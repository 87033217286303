import React from 'react';
import { FormikProps } from 'formik';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import * as Yup from 'yup';
import moment from 'moment';
import ItemForm from 'features/items/lib/Form';
import { IFormValues } from 'features/books/types';
import {
  IFormValues as IItemFormValues,
  IItemMutationInput,
} from 'features/items/types';
import {
  IItem,
  IYupValidationFields,
} from 'features/types';
import getPaperBookQuery from '../../queries/getPaperBookQuery.gql';
import BaseForm from './Form';

interface IBooksFormProps extends WithTranslation {
  data?: IItem;
}

class PaperBooksForm extends React.PureComponent<IBooksFormProps> {
  resolveMutationData = (values: IItemMutationInput) => {
    return values;
  };

  renderForm = (props: FormikProps<IFormValues>) => (<BaseForm {...props} item={this.props.data} />);

  validationSchema = (shape: IYupValidationFields) => {
    const {
      t,
      data,
    } = this.props;

    const messageRequired = (f: string) => t('forms:required_field', { field: t(f) });
    return Yup.object().shape({
      ...shape,
      originalLanguage: Yup.number().min(1, messageRequired('originalLanguage')),
      paperbook: Yup.object().shape({

      }),
    });
  };

  resolveInitialValues = (values: IItemFormValues) => {
    const { data } = this.props;

    const defaultValues = {
      originalLanguage: 1,
      paperbook: {
        isbn: '',
        paper_pages: 0,
        is_preorder: false,
        is_coming_soon: false,
        is_always_in_stock: false,
        publication_type: '',
        height: 0,
        width: 0,
        weight: 0,
        thickness: 0,
        format: '',
        circulation: 0,
        release_date: '',
        commissionAgent: null,
      },
    };

    return data ? {
      ...defaultValues,
      ...values,
      ...{
        originalLanguage: data && data.originalLanguage ? +data.originalLanguage.id : null,
        itemMediaImages: data && data.itemMediaImages.length ? data.itemMediaImages : [],
        itemMediaFiles: data && data.itemMediaFiles.length ? data.itemMediaFiles : [],
        paperbook: {
          isbn: data.paperbook!.isbn || '',
          paper_pages: data.paperbook!.paper_pages || 0,
          is_preorder: data.paperbook!.is_preorder || false,
          is_coming_soon: data.paperbook!.is_coming_soon || false,
          is_always_in_stock: data.paperbook!.is_always_in_stock || false,
          publication_type: data.paperbook!.publication_type || '',
          height: data.paperbook!.height || 0,
          width: data.paperbook!.width || 0,
          weight: data.paperbook!.weight || 0,
          thickness: data.paperbook!.thickness || 0,
          format: data.paperbook!.format || '',
          circulation: data.paperbook!.circulation || 0,
          release_date: (data && data.paperbook) && moment(data.paperbook!.release_date).format('YYYY-MM-DD') || '',
          commissionAgent: (data && data.paperbook!.commissionAgent) && data.paperbook!.commissionAgent.id || null,
        },
      },
    } : {
      ...defaultValues,
      ...values,
    };
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <ItemForm
        type="paperbook"
        data={data}
        getItemQuery={getPaperBookQuery}
        resolveValidationSchema={this.validationSchema}
        resolveInitialValues={this.resolveInitialValues}
        resolveMutationData={this.resolveMutationData}
        renderForm={this.renderForm}
      />
    );
  }
}

export default withTranslation('paperbooks')(PaperBooksForm);
