import React from 'react';
import LinkBlock from 'features/ui/LinkBlock';
import PaperBooksForm from 'features/paperbooks/lib/PaperBooksForm';
import { FEATURE_ICON } from 'features/paperbooks/consts';
import PageEdit from 'features/items/PageEdit';
import { makeItemUrlTo } from 'features/items/lib/url';
import { graphqlById } from 'features/graphql/helpers';
import {
  IBasePageEditProps,
  IItem,
  IItemQueryResponse,
} from 'features/types';
import queryFetchPaperBook from '../queries/getPaperBookQuery.gql';

class BasePageEdit extends React.PureComponent<IBasePageEditProps<IItem>> {
  renderForm(data: IItem) {
    return (
      <React.Fragment>
        <LinkBlock viewLink={makeItemUrlTo(data, 'view')} />
        <PaperBooksForm data={data} />
      </React.Fragment>
    );
  }

  render() {
    const { data } = this.props;
    return (
      <PageEdit
        icon={FEATURE_ICON}
        i18ns="paperbooks"
        {...this.props}
      >
        {data ? this.renderForm(data) : null}
      </PageEdit>
    );
  }
}

export default graphqlById<IItemQueryResponse, IBasePageEditProps<IItem>>({
  query: queryFetchPaperBook,
  queryType: 'ItemQuery',
})(BasePageEdit);
