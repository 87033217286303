export const FEATURE_ICON = 'fa fa-image';
export const BANNER_WIDTH = 1408;
export const BANNER_HEIGHT = 806;
export const BANNER_WIDTH_STANDARD = 1408;
export const BANNER_HEIGHT_STANDARD = 806;
export const BANNER_WIDTH_WIDE = 1410;
export const BANNER_HEIGHT_WIDE = 564;

interface IResolutions { width: number; height: number; }

export const standard: IResolutions = {
  width: 1408,
  height: 806,
};

export const wide_5x2: IResolutions = {
  width: 1410,
  height: 564,
};
export const layout: IResolutions = {
  width: 750,
  height: 1040,
};
export const category: IResolutions = {
  width: 1280,
  height: 480,
};
export const common: IResolutions = {
  width: 2052,
  height: 828,
};

export const common_mobile: IResolutions = {
  width: 640,
  height: 1332,
};

export const big: IResolutions = {
  width: 2052,
  height: 720,
};

export const big_mobile: IResolutions = {
  width: 1160,
  height: 720,
};
