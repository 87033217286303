import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  match as IMatch,
  Redirect,
} from 'react-router';

import CardPage from 'features/ui/Page/CardPage';
import ProductForm from '../lib/ProductForm';
import createProduct from '../queries/createProduct.gql';
import { makeProductUrl } from '../lib/url';
import { PRODUCT_ICON } from '../consts';
import { IProduct } from '../types';

interface IPageCreateProps extends WithTranslation {
  isLoading: boolean;
  match: IMatch<any>;
  product: IProduct;
}

interface IPageCreateState {
  id?: string;
}

class PageCreate extends React.Component<IPageCreateProps, IPageCreateState> {
  constructor(props: IPageCreateProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    const { id } = this.state;
    if (id) {
      return <Redirect to={makeProductUrl({ id })} />;
    }

    return (
      <CardPage
        icon={PRODUCT_ICON}
        title={t('new_product')}
        crumbs={[{ link: makeProductUrl(), name: t('products') }]}
      >
        <ProductForm mutation={createProduct} />
      </CardPage>
    );
  }
}

export default withTranslation('products')(PageCreate);
