import React from 'react';
import PageItems from 'features/items/PageItems';
import { FEATURE_ICON } from 'features/paperbooks/consts';

class PageBooks extends React.PureComponent<{}> {
  render() {
    return (
      <PageItems
        title="Бумажные Книги"
        type="paperbook"
        icon={FEATURE_ICON}
      />
    );
  }
}

export default PageBooks;
