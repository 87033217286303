import React from 'react';
import { get } from 'lodash';
import {
  Col,
  Container,
  Input,
  Row,
} from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'Formik';
import FormRow from 'features/ui/FormRow';
import BaseItemForm from 'features/items/lib/Form/Form';
import FileInfoLink from 'features/ui/FileInfoLink';
import FileUploader from 'features/ui/FileUploader';
import changeFileContentHandler from 'features/items/lib/changeFileContentHandler';
import { CONTENT_TYPE_AUDIOBOOK } from 'features/items/consts';
import {
  RETAIL_ANDROID_BUNDLE_ID,
  RETAIL_IOS_BUNDLE_ID,
  RETAIL_WEB_BUNDLE_ID,
} from 'features/offers/consts';

import styles from './styles.module.scss';

import {
  IItem,
  IItemFormValues,
} from 'features/types';
import CreatorsSelect from 'features/creators/lib/CreatorsSelect';
import AudioTypeSelect from 'features/audiobooks/lib/AudioTypeSelect';

interface IBaseFormProps extends FormikProps<IItemFormValues>, WithTranslation {
  item?: IItem;
}

class BaseForm extends React.PureComponent<IBaseFormProps> {
  hasArchive() {
    const { values } = this.props;
    return !!values.content!.find(c => !c.id);
  }

  onChangeFileContent = (event: any) => {
    changeFileContentHandler(event, CONTENT_TYPE_AUDIOBOOK, this.props.setFieldValue);
  };

  onBlurISBN = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { values } = this.props;

    if (!values.prices.filter(p => !p.delete).length) {
      this.props.setFieldValue('prices', [
        {
          reference_price: 379,
          bundle_id: RETAIL_ANDROID_BUNDLE_ID,
          in_app_id: `gph${e.target.value}`,
        },
        {
          reference_price: 379,
          bundle_id: RETAIL_IOS_BUNDLE_ID,
          in_app_id: `ru.alpinabook.reader.iphone.${e.target.value}.worldwide`,
        },
        {
          reference_price: 379,
          bundle_id: RETAIL_WEB_BUNDLE_ID,
          in_app_id: `ru.alpinabook.reader.web.${e.target.value}`,
        },
      ]);
    }
  };

  onChangeEntityIds = (field: string) => (ids: number[]) => {
    this.props.setFieldValue(field, ids);
  };

  onBlur = (field: string) => {
    this.props.setFieldTouched(field, true);
  };

  resolveFields = (fields: any) => {
    const {
      t,
      values,
      handleBlur,
      handleChange,
    } = this.props;
    fields.splice(0, 0, (
      <FormRow
        key="audiobook.type"
        label={t('type')}
        id="audiobook.type"
      >
        <AudioTypeSelect
          id="audiobook.type"
          name="audiobook.type"
          value={values.audiobook.type}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    ));
    fields.splice(5, 0, (
      <React.Fragment key="content">
        <FormRow
          label={t('content')}
          id="content"
          errorPath="content|content[0].file"
          required
        >
          <FileUploader
            id="content"
            name="content"
            onChange={this.onChangeFileContent}
            onBlur={handleBlur}
          />
        </FormRow>
        {this.hasArchive() ? this.renderWarning() : this.renderListOfAudio()}
      </React.Fragment>
    ));

    fields.splice(13, 0, (
      <FormRow
        key="narrators"
        label={t('narrator')}
        id="narrators"
      >
        <CreatorsSelect
          isMulti
          type="narrator"
          id="narrators"
          name="narrators"
          value={values.narrators}
          onChange={this.onChangeEntityIds('narrators')}
          onBlur={this.onBlur.bind(this, 'narrators')}
        />
      </FormRow>
    ));

    fields.splice(20, 0, [(
      <FormRow
        key="audiobook.duration"
        label={t('duration')}
        id="audiobook.duration"
        required
      >
        <Input
          type="number"
          id="audiobook.duration"
          name="audiobook.duration"
          value={values.audiobook!.duration}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    ), (
      <FormRow
        key="audiobook.isbn"
        label={t('isbn')}
        id="audiobook.isbn"
      >
        <Input
          type="text"
          id="audiobook.isbn"
          name="audiobook.isbn"
          value={values.audiobook!.isbn}
          onChange={handleChange}
          onBlur={this.onBlurISBN}
        />
      </FormRow>
    )]);

    return fields;
  };

  renderListOfAudio() {
    const {
      t,
      item,
      values,
      handleBlur,
      handleChange,
    } = this.props;
    const { content } = values;
    if (item && Array.isArray(content) && content.length) {
      const listOfFiles = content.map(
        ({ id, title, sort, is_preview }, order: number) => {
          const file = get(item, `content[${order}].file`, null);
          return (
            <Container key={id} className={styles.fileListItem}>
              <Row>
                <Col xs={2}>
                  <Input
                    name={`content[${order}].sort`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={sort}
                    data-id={id}
                  />
                </Col>
                <Col xs={8}>
                  <Input
                    name={`content[${order}].title`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={title}
                    data-id={id}
                  />
                </Col>
                <Col xs={2}>
                  <label htmlFor="is_preview" className={styles.previewWrap}>
                    <p>{t('is_preview')}</p>
                    <Input
                      id="is_preview"
                      name={`content[${order}].is_preview`}
                      type="checkbox"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      checked={is_preview}
                    />
                  </label>
                </Col>
              </Row>
              <Row>
                <Col xs={2}>
                  &nbsp;
                </Col>
                <Col xs={10}>
                  <FileInfoLink
                    name={t('content')}
                    file={file}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={2}>
                  &nbsp;
                </Col>
                <Col xs={10}>
                  <audio
                    src={file.path}
                    controls
                    className={styles.fileInfoLink}
                  />
                </Col>
              </Row>
            </Container>
          );
        },
      );
      return (
        <Col sm={{ offset: 3 }} xs="12" md="9">
          {listOfFiles}
        </Col>
      );
    }
    return null;
  }

  renderWarning() {
    const { item } = this.props;
    if (item) {
      return (
        <Col sm={{ offset: 3 }} xs="12" md="9">
          <p className="text-warning">Будут заменены все файлы</p>
        </Col>
      );
    }
    return null;
  }

  render() {
    return (
      <BaseItemForm
        {...this.props}
        resolveFields={this.resolveFields}
        cancelLink={'/audiobooks'}
      />
    );
  }
}

export default withTranslation('audiobooks')(BaseForm);
