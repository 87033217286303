import React from 'react';
import {
  get,
} from 'lodash';
import { DocumentNode } from 'graphql';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import field from 'lib/field';
import filterChangedValues from 'lib/filterChangedValues';
import { stringValidator } from 'lib/validators';
import { crudMutate } from 'features/common/helpers';
import * as Yup from 'yup';
import { makeSeriesUrl } from '../url';
import BaseSeriesForm from './BaseSeriesForm';

import {
  ISeries,
  ISeriesFormValues,
} from '../../types';

interface ISeriesFormProps extends WithTranslation {
  series?: ISeries;
  mutation: DocumentNode;
}

class SeriesForm extends React.Component<ISeriesFormProps> {
  getValidationScheme() {
    const { t } = this.props;
    const required = true;
    const validators: any = {
      name: stringValidator({ required, field: t('name') }),
    };
    return Yup.object().shape(validators);
  }

  getChangedValues(values: ISeriesFormValues) {
    const formData = this.buildFormData();
    if (formData) {
      return filterChangedValues(formData, values);
    }
    return values;
  }

  onSubmit = (
    formData: ISeriesFormValues,
    formActions: FormikActions<ISeriesFormValues>,
  ) => {
    const { mutation } = this.props;
    const id = get(this.props, 'series.id', undefined);
    const variables = (id ? { id, serie: this.getChangedValues(formData) } : { serie: { ...formData } });

    if (variables.serie.items) {
      variables.serie.items = variables.serie.items.map((itemId: number) => ({ id: itemId }));
    }

    crudMutate({
      id,
      formActions,
      variables,
      mutation,
      redirect: makeSeriesUrl(),
      check: !!Object.keys(variables.serie).length,
    });
  };

  buildFormData(): ISeriesFormValues {
    const { series } = this.props;
    return {
      name: field(series, 'name', ''),
      items: series && series.items.length ? series.items.map(({ id }) => id) : [],
    };
  }

  renderForm = (props: FormikProps<ISeriesFormValues>) => {
    const { series } = this.props;
    return (
      <BaseSeriesForm
        {...props}
        series={series}
      />
    );
  };

  render() {
    return (
      <Formik
        enableReinitialize
        initialValues={this.buildFormData()}
        onSubmit={this.onSubmit}
        validationSchema={this.getValidationScheme()}
        render={this.renderForm}
      />
    );
  }
}

export default withTranslation('series')(SeriesForm);
