import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import { flow } from 'lodash';
import {
  match as IMatch,
  Redirect,
} from 'react-router';

import CardPage from 'features/ui/Page/CardPage';
import LinkBlock from 'features/ui/LinkBlock';
import { IListQueryVariables } from 'features/types';
import deepOmit from 'lib/deepOmit';
import Spinner from 'features/ui/Spinner';
import { IProduct, IProductResponse } from '../types';
import ProductForm from '../lib/ProductForm';
import {
  makeProductUrl,
  makeProductUrlTo,
} from '../lib/url';
import getProductQuery from '../queries/getProductQuery.gql';
import editProductMutation from '../queries/editProduct.gql';
import { PRODUCT_ICON } from '../consts';

interface IPageEditProps extends WithTranslation {
  isLoading: boolean;
  match: IMatch<any>;
  product: IProduct;
}

class PageEdit extends React.Component<IPageEditProps> {
  render() {
    const {
      product,
      isLoading,
      t,
    } = this.props;

    if (!isLoading && !product) {
      return <Redirect to="/404" />;
    }

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <CardPage
        icon={PRODUCT_ICON}
        title={product ? `${t('edit_product')}: ${product.name}` : ''}
        crumbs={[{ link: makeProductUrl(), name: t('products') }]}
      >
        <LinkBlock viewLink={makeProductUrlTo(product, 'view')} />
        <ProductForm
          product={product}
          mutation={editProductMutation}
        />
      </CardPage>
    );
  }
}

const mapResultsToProps = (props: OptionProps<IPageEditProps, IProductResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;
  if (data && data.ProductQuery) {
    const product = deepOmit(data.ProductQuery.items[0], '__typename');
    return {
      product,
      isLoading: data && data.loading,
      ...ownProps,
    };
  }
  return {
    product: null,
    isLoading: data && data.loading,
    ...ownProps,
  };
};

export default flow(
  graphql(getProductQuery, {
    props: mapResultsToProps,
    options: ({ match }: { match: IMatch<any> }) => {
      const { params } = match;
      const { id } = params || { id: 0 };
      return {
        fetchPolicy: 'network-only',
        context: {
          isGlobalLoading: true,
        },
        variables: {
          id: [id],
        },
      };
    },
  }),
  withTranslation('products'),
)(PageEdit);
