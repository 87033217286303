import React from 'react';
import i18n from 'features/intl/i18n';
import { filterInputHandler } from 'features/common';
import ListOfOffers from 'features/offers/lib/ListOfOffers';
import OffersSelect from 'features/offers/lib/OffersSelect';

import { IProduct } from 'features/products/types';

export default () => [
  {
    Header: i18n.t('products:name'),
    accessor: 'name',
    minWidth: 100,
    Filter: filterInputHandler,
  },
  {
    Cell: ({ value }: { value: IProduct }) => <ListOfOffers offers={value.offers ? value.offers : []} />,
    Header: i18n.t('offers:offers'),
    accessor: (data: any) => data,
    expandable: true,
    Filter: ({ filter, onChange }: any) => (
      <OffersSelect
        onChange={onChange}
        value={filter && filter.value}
        isMulti={false}
      />
    ),
    id: 'offers',
    maxWidth: 400,
  },
];
