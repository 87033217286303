import React from 'react';
import { Link } from 'react-router-dom';
import { makeItemUrl } from 'features/items/lib/url';
import { ISeries } from '../../types';

export default ({ series }: { series: ISeries }) => (
  <Link to={makeItemUrl({ query: { series: series.id } })}>
    Перейти к итемам этой серии ({series.items.length})
  </Link>
);
