import { ICreator } from 'features/types';

export interface ISeparatedCreators {
  author: ICreator[];
  publisher: ICreator[];
  provider: ICreator[];
  lecturer: ICreator[];
  narrator: ICreator[];
  speaker: ICreator[];
  translator: ICreator[];
  [key: string]: ICreator[];
}

export default (creators?: ICreator[]) => {
  const map: ISeparatedCreators = {
    author: [],
    publisher: [],
    provider: [],
    lecturer: [],
    narrator: [],
    speaker: [],
    translator: [],
  };
  if (creators) {
    creators.forEach((creator) => {
      if (creator.type in map) {
        map[creator.type].push({ ...creator });
      }
    });
  }
  return map;
};
