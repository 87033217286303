import React from 'react';
import {
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import FixedOnTheRight from 'features/ui/FixedOnTheRightContainer';
import {
  mapEntityToOptions,
  ReactSelectWithId,
} from 'features/ui/ReactSelect';
import { ILayout } from 'features/layouts/types';
import getLayouts from 'features/blocks/lib/getLayouts';
import graphql from './graphql';

interface IOLayoutsSelectProps extends ISharedSelectProps {
  data: ILayout[];
}

interface ILayoutsSelectState {
  options: ISelectOption[];
}

class OffersSelect extends React.PureComponent<IOLayoutsSelectProps, ILayoutsSelectState> {
  static getDerivedStateFromProps(props: any) {
    return {
      options: mapEntityToOptions<ILayout>(props.data, 'name'),
    };
  }

  state = {
    options: [],
  };

  onClearOffers = () => {
    const { onChange } = this.props;
    if (onChange) {
      onChange([]);
    }
  };

  onAddAllOffers = () => {
    getLayouts().then((res) => {
      if (res.data && res.data.LayoutQuery) {
        const { onChange } = this.props;
        if (onChange) {
          onChange(res.data.LayoutQuery.items.map(layout => layout.id));
        }
      }
    });
  };

  render() {
    const {
      id,
      name,
      value,
      onBlur,
      onChange,
    } = this.props;

    const {
      options,
    } = this.state;

    const side = (
      <React.Fragment>
        <a
          className="btn btn-warning"
          onClick={this.onAddAllOffers}
          title="Добавляет все Лейауты"
        >
          Добавить Все
        </a>
        <a
          className="btn btn-warning"
          onClick={this.onClearOffers}
        >
          Очистить
        </a>
      </React.Fragment>
    );

    return (
      <FixedOnTheRight side={side}>
        <ReactSelectWithId
          id={id}
          name={name}
          options={options}
          isClearable={false}
          isMulti={true}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          minSearchLength={1}
        />
      </FixedOnTheRight>
    );
  }
}
export default graphql(OffersSelect);
