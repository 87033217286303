import React, { useRef } from 'react';
import { plugins, toolbar } from 'features/articles/lib/ContentEditor/consts';
import { client } from 'features/graphql';
import LoadImageMutation from 'features/articles/queries/LoadImageMutation.gql';
import Editor from './Base';

interface IContentEditorProps {
  onContentChange: (s: string) => void;
  value: string;
}
const ContentEditor = ({ onContentChange, value }: IContentEditorProps) => {
  const editorRef = useRef(null);
  const onEditorChange = (a: string) => onContentChange(a);
  const onInit = (_evt: any, editor: any) => { editorRef.current = editor; };
  const init = {
    plugins,
    toolbar,
    image_advtab: true,
    image_caption: true,
    images_upload_ur: '',
    content_css: false,
    license_key: 'gpl',
    height: 500,
    menubar: false,
    toolbar_mode: 'sliding',
    lists_indent_on_tab: false,
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    images_upload_handler: (image: any) => new Promise((resolve, reject) => {
      try {
        client.mutate({
          mutation: LoadImageMutation,
          variables: {
            image: image.blob(),
          },
        }).then((res) => {
          const imagePath = res.data.ImageMutation.path;
          resolve(imagePath);
        });
      } catch (err) {
        reject(err);
      }
    }),
  };

  return (
    <Editor
      onInit={onInit}
      value={value}
      onEditorChange={onEditorChange}
      init={init}
    />
  );
};

export default ContentEditor;
