import React from 'react';
import { Input } from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'formik';
import FormFrame from 'features/ui/FormFrame';
import FormRow from 'features/ui/FormRow';
import OffersSelectWithButtons from 'features/offers/lib/OffersSelectWithButtons';
import { makeProductUrl } from '../url';
import { IProduct, IProductFormValues } from '../../types';
import LanguagesSelect from 'features/languages/LanguagesSelect';
import Switch from 'features/ui/Switch';
import ImageUploader from 'features/ui/ImageUploader';
import styles from 'features/items/lib/Form/styles.module.scss';
import BadgesSelect from 'features/badges/lib/BadgesSelect';
import CategoriesSelect from 'features/categories/lib/CategoriesSelect';
import CreatorsSelect from 'features/creators/lib/CreatorsSelect';
import ItemsSelect from 'features/items/lib/ItemsSelect';
import DatePicker from 'features/ui/DatePicker';
import { Moment } from 'features/types';

interface IProductFormProps extends FormikProps<IProductFormValues>, WithTranslation {
  product?: IProduct;
}

class BaseProductForm extends React.Component<IProductFormProps> {
  onChange = (name: string, value: number | string | null | string[]) => {
    const { setFieldValue } = this.props;
    setFieldValue(name, value);
  };

  onBlur = (field: string) => {
    const { setFieldTouched } = this.props;
    setFieldTouched(field, true);
  };

  onChangeOffers = (ids: ID[]) => {
    this.onChange('offers', ids.slice());
  };

  onChangeEntityId = (field: string) => (id: number) => {
    this.props.setFieldValue(field, id);
  };

  onChangeImage = (e: React.ChangeEvent<HTMLInputElement> | null) => {
    if (e === null) {
      this.props.setFieldValue('image', null);
    } else {
      if (e.target.validity.valid) {
        this.props.setFieldValue('image', e.target.files[0]);
      }
    }
  };

  onNumericChange = (field: string, value: string) => {
    this.props.setFieldValue(field, +value);
  };

  parseImagesLinks(images: []) {
    return images.map((image: any, index: number) => (
      <p key={index} className={styles.imageLinks}>
        <a href={image.path} target="_blank">
          {image.height}x{image.width}
        </a>
      </p>
    ));
  }

  onChangeDate = (date: Moment | null, field: string) => {
    const { setFieldValue } = this.props;
    setFieldValue(field, date ? date.format('YYYY-MM-DD') : null);
  }

  renderImage() {
    const {
      t,
      values,
      handleBlur,
    } = this.props;

    return (
      <FormRow
        key="image"
        label={t('image')}
        id="image"
        text={t('forms:expected_width', { width: 1024 })}
        required
      >
        <ImageUploader
          id="image"
          name="image"
          value={values.image && values.image.length ? values.image[0].path : values.image}
          onChange={this.onChangeImage}
          onBlur={handleBlur}
          imageWidth={256}
        />
        {values.image && values.image.length ? this.parseImagesLinks(values.image) : null}
      </FormRow>
    );
  }

  render() {
    const {
      product,
      handleBlur,
      handleChange,
      t,
      values,
    } = this.props;

    return (
      <FormFrame
        id={product ? product.id : undefined}
        cancelLink={makeProductUrl()}
        {...this.props}
      >
        <FormRow label={t('name')} id="name" required>
          <Input
            type="text"
            id="name"
            name="name"
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormRow>

        <FormRow label={t('sub_name')} id="sub_name">
          <Input
            type="text"
            id="sub_name"
            name="sub_name"
            value={values.sub_name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormRow>

        <FormRow label={t('short_name')} id="short_name">
          <Input
            type="text"
            id="short_name"
            name="short_name"
            value={values.short_name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormRow>

        <FormRow label={t('original_name')} id="original_name">
          <Input
            type="text"
            id="original_name"
            name="original_name"
            value={values.original_name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormRow>

        <FormRow label={t('seo_bottom_text')} id="seo_bottom_text">
          <Input
            type="text"
            id="seo_bottom_text"
            name="seo_bottom_text"
            value={values.seo_bottom_text}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormRow>

        <FormRow label={t('seo_description')} id="seo_description">
          <Input
            type="text"
            id="seo_description"
            name="seo_description"
            value={values.seo_description}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormRow>

        {this.renderImage()}
        <FormRow label={t('description')} id="description">
          <Input
            type="text"
            id="description"
            name="description"
            value={values.description}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormRow>

        <FormRow label={t('external_id')} id="external_id">
          <Input
            type="text"
            id="external_id"
            name="external_id"
            value={values.external_id}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormRow>

        <FormRow label={t('age_limit')} id="age_limit">
          <Input
            type="number"
            id="age_limit"
            name="age_limit"
            value={values.age_limit}
            onBlur={handleBlur}
            onChange={e => this.onNumericChange('age_limit', e.target.value)}
          />
        </FormRow>

        <FormRow label={t('release_date')} id="release_date">
          <DatePicker
            id="release_date"
            value={Date.parse(values.release_date)}
            onChange={date => this.onChangeDate(date, 'release_date')}
          />
        </FormRow>

        <FormRow label={t('sort')} id="sort">
          <Input
            type="number"
            id="sort"
            name="sort"
            value={values.sort}
            onBlur={handleBlur}
            onChange={e => this.onNumericChange('sort', e.target.value)}
          />
        </FormRow>

        <FormRow label={t('year')} id="year">
          <Input
            type="number"
            id="year"
            name="year"
            value={values.year}
            onBlur={handleBlur}
            onChange={e => this.onNumericChange('year', e.target.value)}
          />
        </FormRow>

        <FormRow label={t('is_active')} id="is_active">
          <Switch
            id="is_active"
            name="is_active"
            checked={values.is_active}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow key="language" label={t('language')} id="language" required>
          <LanguagesSelect
            id="language"
            name="language"
            value={values.language}
            onChange={this.onChangeEntityId('language')}
            onBlur={this.onBlur.bind(this, 'language')}
          />
        </FormRow>

        <FormRow key="originalLanguage" label={t('originalLanguage')} id="originalLanguage">
          <LanguagesSelect
            id="originalLanguage"
            name="originalLanguage"
            value={values.originalLanguage}
            onChange={this.onChangeEntityId('originalLanguage')}
            onBlur={this.onBlur.bind(this, 'originalLanguage')}
          />
        </FormRow>

        <FormRow key="badges" label={t('badges')} id="badges">
          <BadgesSelect
            id="badges"
            name="badges"
            value={values.badges}
            onChange={this.onChangeEntityId('badges')}
            onBlur={this.onBlur.bind(this, 'badges')}
          />
        </FormRow>

        <FormRow label={t('categories')} id="categories">
          <CategoriesSelect
            isMulti={true}
            id="categories"
            name="categories"
            value={values.categories}
            onChange={this.onChangeEntityId('categories')}
            onBlur={this.onBlur.bind(this, 'categories')}
          />
        </FormRow>

        <FormRow label={t('mainCategory')} id="mainCategory">
          <CategoriesSelect
            isMulti={false}
            id="mainCategory"
            name="mainCategory"
            value={values.mainCategory}
            onChange={this.onChangeEntityId('mainCategory')}
            onBlur={this.onBlur.bind(this, 'mainCategory')}
          />
        </FormRow>

        <FormRow label={t('creators')} id="creators">
          <CreatorsSelect
            type="author"
            id="creators"
            name="creators"
            value={values.creators}
            onChange={this.onChangeEntityId('creators')}
            onBlur={this.onBlur.bind(this, 'creators')}
          />
        </FormRow>

        <FormRow id="items" label={t('items')}>
          <ItemsSelect
            id="items"
            name="items"
            isMulti={true}
            value={values.items}
            isClearable
            onChange={this.onChangeEntityId('items')}
          />
        </FormRow>

        <FormRow key="offers" label={t('offers:offers')} id="offers">
          <OffersSelectWithButtons
            id="offers"
            name="offers"
            value={values.offers}
            onChange={this.onChangeOffers}
          />
        </FormRow>
      </FormFrame>
    );
  }
}

export default withTranslation('products')(BaseProductForm);
