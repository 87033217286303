import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import { Page404 } from 'features/http';
import { AccessControl } from 'features/acl';
import AppRoute from 'features/app/Route';
import PageLayouts from '../PageProducts';
import PageEdit from '../PageEdit';
import PageView from '../PageView';
import PageCreate from '../PageCreate';

export default () => (
  <AccessControl permission="products:access">
    <Switch>
      <AppRoute path="/products" menuId="products" component={PageLayouts} exact />
      <AppRoute path="/product/create" menuId="products" component={PageCreate} exact />
      <AppRoute path="/product/edit/:id" menuId="products" component={PageEdit} exact />
      <AppRoute path="/product/:id" menuId="products" component={PageView} exact />
      <Route name="404" component={Page404} />
    </Switch>
  </AccessControl>
);
