import React from 'react';
import {
  get,
} from 'lodash';
import { DocumentNode } from 'graphql';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import field from 'lib/field';
import filterChangedValues from 'lib/filterChangedValues';
import { stringValidator } from 'lib/validators';
import { crudMutate } from 'features/common/helpers';
import * as Yup from 'yup';
import { makeProductUrl } from '../url';
import BaseProductForm from './BaseProductForm';

import {
  IProduct,
  IProductFormValues,
} from '../../types';

interface IProductFormProps extends WithTranslation {
  product?: IProduct;
  mutation: DocumentNode;
}

class ProductForm extends React.Component<IProductFormProps> {
  getValidationScheme() {
    const { t } = this.props;
    const required = true;
    const validators: any = {
      name: stringValidator({ required, field: t('name') }),
    };
    return Yup.object().shape(validators);
  }

  getChangedValues(values: IProductFormValues) {
    const formData = this.buildFormData();
    if (formData) {
      return filterChangedValues(formData, values);
    }
    return values;
  }

  onSubmit = (
    formData: IProductFormValues,
    formActions: FormikActions<IProductFormValues>,
  ) => {
    const { mutation } = this.props;
    const id = get(this.props, 'product.id', undefined);

    const variables = (id ? { id, product: this.getChangedValues(formData) } : { product: { ...formData } });
    crudMutate({
      id,
      formActions,
      variables,
      mutation,
      redirect: makeProductUrl(),
      check: !!Object.keys(variables.product).length,
    });
  };

  buildFormData(): IProductFormValues {
    const { product } = this.props;
    return {
      name: field(product, 'name', ''),
      sub_name: field(product, 'sub_name', ''),
      short_name: field(product, 'short_name', ''),
      original_name: field(product, 'original_name', ''),
      seo_bottom_text: field(product, 'seo_bottom_text', ''),
      seo_description: field(product, 'seo_description', ''),
      description: field(product, 'description', ''),
      external_id: field(product, 'external_id', ''),
      language: field(product, 'language.id', ''),
      originalLanguage: field(product, 'originalLanguage.id', ''),
      release_date: field(product, 'release_date', ''),
      mainCategory: field(product, 'mainCategory.id', ''),
      is_active: field(product, 'is_active', false),
      year: field(product, 'year', 0),
      age_limit: field(product, 'age_limit', 0),
      sort: field(product, 'sort', 0),
      image: field(product, 'images', []),
      offers: product && product.offers ? product.offers.map(offer => offer.id) : [],
      creators: product && product.creators ? product.creators.map(creator => creator.id) : [],
      items: product && product.items ? product.items.map(item => item.id) : [],
      badges: product && product.badges ? product.badges.map(badge => badge.id) : [],
      categories: product && product.categories ? product.categories.map(cat => cat.id) : [],
    };
  }

  renderForm = (props: FormikProps<IProductFormValues>) => {
    const { product } = this.props;
    return (
      <BaseProductForm
        {...props}
        product={product}
      />
    );
  };

  render() {
    return (
      <Formik
        initialValues={this.buildFormData()}
        onSubmit={this.onSubmit}
        validationSchema={this.getValidationScheme()}
        render={this.renderForm}
      />
    );
  }
}

export default withTranslation('products')(ProductForm);
