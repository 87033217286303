import React from 'react';
import { Input } from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'formik';
import FormFrame from 'features/ui/FormFrame';
import FormRow from 'features/ui/FormRow';
import ItemsSelect from 'features/items/lib/ItemsSelect';
import { blockParams } from 'features/blocks/consts';
import ColorPicker from 'features/ui/ColorPicker';
import { client, gql } from 'features/graphql';
import ImageUploader from 'features/ui/ImageUploader';
import ReactSelect from 'features/ui/ReactSelect/ReactSelect';
import SetSelect from 'features/blocks/lib/SetSelect';
import DatePicker from 'features/ui/DatePicker';
import { Moment } from 'features/types';
import LayoutsSelect from 'features/blocks/lib/LayoutsSelect';
import { makeLayoutBlockUrl } from '../url';
import BlockTypeSelector from '../BlockTypeSelector';
import ReferenceSelect from '../ReferenceSelect';
import { itemTypesOptions } from '../../consts';
import {
  IBlock,
  ILayoutFormValues,
} from '../../types';
import StoriesTypeSelector from 'features/blocks/lib/StoriesTypeSelector';

interface ILayoutFormProps extends FormikProps<ILayoutFormValues>, WithTranslation {
  block?: IBlock;
}

interface ILayoutFormState {
  currentLayoutType: string;
}

class BaseLayoutForm extends React.Component<ILayoutFormProps, ILayoutFormState> {
  constructor(props: ILayoutFormProps) {
    super(props);
    this.state = {
      currentLayoutType: '',
    };
  }

  componentDidMount() {
    const { setFieldValue } = this.props;
    const QUERY = gql`
      query getBlockTypes($page: Int, $perPage: Int) {
        LayoutBlockTypeQuery(page: $page, perPage: $perPage) {
          items {
            id
            name
            slug
          }
        }
      }
    `;
    client.query({
      query: QUERY,
      variables: {
        page: 1,
        perPage: 50,
      },
    }).then((res) => {
      const { values: { type } } = this.props;
      const variants = res.data.LayoutBlockTypeQuery.items;
      if (variants.length && type) {
        const currentLayoutType = variants.find((variant: IBlock) => variant.id === type).slug;
        setFieldValue('slug', currentLayoutType);
        this.setState({ currentLayoutType });
      }
    }).catch(err => global.console.error(`[Error]: ${err}`));
  }

  onChangeImage = (e: any) => {
    const { handleChange, setFieldValue } = this.props;
    if (e.target.validity.valid) {
      handleChange(e);
      setFieldValue('view.image', e.target.files[0]);
    }
  };

  onTypeChange = (id: number, ds: any, fields: any) => {
    const { setFieldValue } = this.props;
    setFieldValue('type', id);
    setFieldValue('slug', fields.slug);
    this.setState({ currentLayoutType: fields.slug });
  };

  onChangeColor = (color: string) => {
    const { setFieldValue } = this.props;
    setFieldValue('view.bg_color', color);
  };

  onChangeItemTypes = (types: number[]) => {
    const parseTypes = types.map(typeValue => itemTypesOptions.find(option => option.value === typeValue).key);
    const { setFieldValue } = this.props;
    setFieldValue('view.item_types', parseTypes);
  }

  onItemsChange = (values: number[]) => {
    const { setFieldValue } = this.props;
    setFieldValue('items', [...values]);
  }

  onReferencesChange = (value: number) => {
    const { values, setFieldValue } = this.props;
    setFieldValue('references', [...values.references, value]);
  }

  onReferencesDelete = (value: number) => {
    const { values, setFieldValue } = this.props;
    setFieldValue('references', values.references.filter(v => v !== value));
  }

  onSetChange = (value: ILayoutFormValues) => {
    const { setFieldValue } = this.props;
    setFieldValue('sets', [...value]);
  }

  onLayoutsChange = (value: number[]) => {
    const { setFieldValue } = this.props;
    setFieldValue('layouts', value);
  }

  onItemChange = (value: number) => {
    const { setFieldValue } = this.props;
    setFieldValue('items', value);
  }

  onStoryStyleChange = (value: string) => {
    const { setFieldValue } = this.props;
    setFieldValue('view.feed_style', value);
  }

  renderAdditionalFields = (param: string) => {
    const { currentLayoutType } = this.state;
    if (!currentLayoutType) {
      return null;
    }
    return blockParams[currentLayoutType].params.includes(param) || false;
  }

  parseTypesValues = (values: any) => {
    if (!values.view.item_types) {
      return null;
    }
    return values.view.item_types.map((type: string) => itemTypesOptions.find(option => option.key === type).value);
  }

  parseSetsValues = (values: any) => {
    if (!values.sets && values.sets.length) {
      return null;
    }
    if (values.sets[0] && values.sets[0].id) {
      return +values.sets.map(set => set.id);
    }
    return values.sets;
  }

  onChangeDate = (date: Moment | null, field: string) => {
    const { setFieldValue } = this.props;
    setFieldValue(field, date ? date.format('YYYY-MM-DD') : null);
  }

  onLinkChange = (event: string) => {
    const { setFieldValue } = this.props;
    setFieldValue('view.link', event.target.value);
  }

  render() {
    const {
      block,
      handleBlur,
      handleChange,
      t,
      values,
    } = this.props;

    return (
      <FormFrame
        id={block ? block.id : undefined}
        cancelLink={makeLayoutBlockUrl()}
        {...this.props}
      >
        <FormRow label={t('name')} id="name" required>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder={t('name')}
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow label={t('type')} id="type" required>
          <BlockTypeSelector
            id="type"
            name="type"
            value={values.type}
            onChange={this.onTypeChange}
          />
        </FormRow>
        {this.renderAdditionalFields('references') ? (
          <FormRow id="references" label={t('references')}>
            <ReferenceSelect
              id="references"
              name="references"
              value={values.references}
              onChange={this.onReferencesChange}
              onDelete={this.onReferencesDelete}
            />
          </FormRow>
        ) : null}
        {this.renderAdditionalFields('items') ? (
          <FormRow id="items" label={t('items')}>
            <ItemsSelect
              id="items"
              name="items"
              sortable
              isMulti={true}
              value={values.items}
              isClearable
              onChange={this.onItemsChange}
            />
          </FormRow>
        ) : null}
        {this.renderAdditionalFields('image') ? (
          <FormRow
            label={t('image')}
            id="view.image"
            text={t('forms:expected_size', { width: values.slug === 'thin_block_with_link' ? 124 : 352, height: values.slug === 'thin_block_with_link' ? 124 : 352 })}
            required
            shouldUpdate={() => true}
          >
            <ImageUploader
              id="view.image"
              name="view.image"
              value={values.view && values.view.image && values.view.image.path ? values.view.image.path : undefined}
              onChange={this.onChangeImage}
              onBlur={handleBlur}
              imageWidth={values.slug === 'thin_block_with_link' ? 124 : 352}
              imageHeight={values.slug === 'thin_block_with_link' ? 124 : 352}
            />
          </FormRow>
        ) : null}
        {this.renderAdditionalFields('link') ? (
          <FormRow label={t('link')} id="view.link" required>
            <Input
              type="text"
              id="view.link"
              name="view.link"
              placeholder={t('link')}
              value={values.view.link}
              onBlur={handleBlur}
              onChange={this.onLinkChange}
            />
          </FormRow>
        ): null}
        {this.renderAdditionalFields('item') ? (
          <FormRow id="items" label={t('items')}>
            <ItemsSelect
              id="items"
              name="items"
              isMulti={false}
              value={values.items}
              isClearable
              onChange={this.onItemChange}
            />
          </FormRow>
        ) : null}
        {this.renderAdditionalFields('feed_name') ? (
          <FormRow label={t('feed_name')} id="view.feed_name" required>
            <Input
              type="text"
              id="view.feed_name"
              name="view.feed_name"
              placeholder={t('feed_name')}
              value={values.view.feed_name}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormRow>
        ) : null}
        {this.renderAdditionalFields('feed_style') ? (
          <FormRow label={t('feed_style')} id="view.feed_style">
            <StoriesTypeSelector
              id="view.feed_style"
              name="view.feed_style"
              value={values.view.feed_style}
              onBlur={handleBlur}
              onChange={this.onStoryStyleChange}
            />
          </FormRow>
        ) : null}
        {this.renderAdditionalFields('description') ? (
          <FormRow label={t('description')} id="view.description">
            <Input
              type="text"
              id="view.description"
              name="view.description"
              value={values.view.description}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormRow>
        ) : null}
        {this.renderAdditionalFields('bg_color') ? (
          <FormRow label={t('bg_color')} id="view.bg_color" text={t('forms:example', { text: '#3a4b5c' })}>
            <ColorPicker
              id="view.bg_color"
              name="view.bg_color"
              placeholder={t('bg_color')}
              color={values.view.bg_color}
              onChange={this.onChangeColor}
            />
          </FormRow>
        ) : null}
        {this.renderAdditionalFields('item_types') ? (
          <FormRow label={t('item_types')} id="view.item_types" required>
            <ReactSelect
              id="view.item_types"
              name="view.item_types"
              isMulti={true}
              options={itemTypesOptions}
              value={this.parseTypesValues(values)}
              onChange={this.onChangeItemTypes}
            />
          </FormRow>
        ) : null}
        {this.renderAdditionalFields('sets_items') ? (
          <FormRow
            label={t('sets_items')}
            id="view.sets_items"
            shouldUpdate={() => true}
            required
            text="Выберите 3 подборки"
          >
            <SetSelect
              id="sets_items"
              name="sets_items"
              isClearable
              isMulti={true}
              value={this.parseSetsValues(values)}
              onChange={this.onSetChange}
            />
          </FormRow>
        ) : null}
        {this.renderAdditionalFields('date') ? (
          <FormRow key="view.begin_date" label={t('begin_date')} id="view.begin_date">
            <DatePicker
              id="view.begin_date"
              value={Date.parse(values.view.begin_date)}
              onChange={(date) => this.onChangeDate(date, 'view.begin_date')}
            />
          </FormRow>
        ) : null}
        {this.renderAdditionalFields('date') ? (
          <FormRow key="view.end_date" label={t('end_date')} id="view.end_date">
            <DatePicker
              id="view.end_date"
              value={Date.parse(values.view.end_date)}
              onChange={(date) => this.onChangeDate(date, 'view.end_date')}
            />
          </FormRow>
        ) : null}
        <FormRow label={t('layouts')} id="layouts">
          <LayoutsSelect
            id="layouts"
            name="layouts"
            onChange={this.onLayoutsChange}
            value={values.layouts}
          />
        </FormRow>
        <FormRow label={t('position')} id="position">
          <Input
            type="text"
            id="position"
            name="position"
            placeholder={t('position')}
            value={values.position}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormRow>
      </FormFrame>
    );
  }
}

export default withTranslation('blocks')(BaseLayoutForm);
