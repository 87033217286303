import React from 'react';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import * as Yup from 'yup';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import filterChangedValues from 'lib/filterChangedValues';
import {
  imageFileValidator,
  stringValidator,
} from 'lib/validators';
import field from 'lib/field';
import { crudMutate } from 'features/common/helpers';
import { makeBadgeUrl } from '../url';
import editBadgeMutation from '../../queries/editBadge.gql';
import createBadgeMutation from '../../queries/createBadge.gql';
import BaseBadgeForm from './BaseBadgeForm';

import {
  IBadge,
  IBadgeFormValues,
} from '../../types';

interface IBadgeFormProps extends WithTranslation {
  badge?: IBadge;
}

class BadgeForm extends React.Component<IBadgeFormProps> {
  getScheme() {
    const {
      t,
      badge,
    } = this.props;
    const required = true;
    const validators: any = {
      name: stringValidator({ required, field: t('name') }),
      image: imageFileValidator({
        field: t('image'),
        required: !badge,
      }),
    };
    return Yup.object().shape(validators);
  }

  getChangedValues(values: IBadgeFormValues) {
    const formData = this.buildFormData();
    if (formData) {
      return filterChangedValues(formData, values);
    }
    return { ...values };
  }

  onSubmit = (values: IBadgeFormValues, formActions: FormikActions<IBadgeFormValues>) => {
    const { badge } = this.props;
    let variables: { id?: ID, badge: object };
    let id;
    if (badge) {
      id = badge.id;
      variables = { id, badge: this.getChangedValues(values) };
    } else {
      variables = { badge: { ...values } };
    }
    crudMutate({
      id,
      variables,
      formActions,
      mutation: badge ? editBadgeMutation : createBadgeMutation,
      redirect: makeBadgeUrl(),
      check: !!Object.keys(variables.badge).length,
    });
  };

  buildFormData() {
    const { badge } = this.props;
    return {
      name: field(badge, 'name', ''),
      image: field(badge, 'image.path', null),
      color: field(badge, 'color', ''),
      key: field(badge, 'key', ''),
    };
  }

  renderForm = (props: FormikProps<IBadgeFormValues>) => {
    const { badge } = this.props;
    return (
      <BaseBadgeForm {...props} badge={badge} />
    );
  };

  render() {
    return (
      <Formik
        enableReinitialize
        initialValues={this.buildFormData()}
        onSubmit={this.onSubmit}
        validationSchema={this.getScheme()}
        render={this.renderForm}
      />
    );
  }
}

export default withTranslation('badges')(BadgeForm);
