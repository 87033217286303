import React from 'react';
import { client, gql } from 'features/graphql';
import { graphql, OptionProps } from 'react-apollo';
import { ISelectOption, ISharedSelectProps } from 'features/ui/ReactSelect/types';
import { IListQueryVariables } from 'features/types';
import ReactSelect from 'features/ui/ReactSelect';
import { ICommissionAgent, ICommissionAgentQueryResponse } from 'features/paperbooks/types';

interface ICommissionAgentSelectProps extends ISharedSelectProps {
  data: ICommissionAgent[];
  withOfferId?: boolean;
}

interface ICommissionAgentSelectState {
  options: ISelectOption[];
}

class CommissionAgentSelect extends React.Component<ICommissionAgentSelectProps, ICommissionAgentSelectState> {
  static getDerivedStateFromProps(props: ICommissionAgentSelectProps) {
    const { data } = props;
    return {
      options: CommissionAgentSelect.mapDataToOptions(data),
    };
  }

  static mapDataToOptions = (data: ICommissionAgent[]) => data.map(item => ({
    value: item.id,
    label: `${item.inn} | ${item.name}`,
  }))

  loadOptions = (value: string) => client.query({
    query: QUERY,
    variables: {
      pageNum: 1,
      perPage: 10,
      filterByFields: {
        email: {
          value,
          operator: 'like',
        },
      },
    },
  }).then((res) => {
    if (res.data && res.data.CommissionAgentQuery) {
      return CommissionAgentSelect.mapDataToOptions(res.data.CommissionAgentQuery.items);
    }
    return [];
  });

  render() {
    const {
      id,
      name,
      value,
      onBlur,
      onChange,
      isClearable,
    } = this.props;
    const { options } = this.state;
    return (
      <ReactSelect
        id={id}
        name={name}
        options={options}
        isMulti={false}
        value={value}
        isClearable={isClearable}
        async
        loadOptions={this.loadOptions}
        minSearchLength={1}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }
}

const QUERY = gql`
  query CommissionAgentQuery(
    $pageNum: Int
    $perPage: Int
  ) {
    CommissionAgentQuery(
      page: $pageNum
      perPage: $perPage
    ) {
      items {
        id
        name
        inn
        phone
        created_at
        updated_at
      }
    }
  }
`;

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, ICommissionAgentQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;

  return {
    data: data && data.CommissionAgentQuery ? data.CommissionAgentQuery.items : [],
    ...ownProps,
  };
};

export default graphql<ISharedSelectProps, ICommissionAgentQueryResponse, IListQueryVariables, any>(
  QUERY,
  {
    props: mapResultsToProps,
    options: ({ value }) => ({
      variables: {
        id: value && value.length ? String(value).split(',') : undefined,
        pageNum: 1,
        perPage: 100,
      },
    }),
  },
)(CommissionAgentSelect);
