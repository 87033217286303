import React, { Component } from 'react';
import { match as IMatch } from 'react-router';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { History } from 'history';
import { set } from 'lodash';

import CardPage from 'features/ui/Page/CardPage';
import IndexTableBase, { IControlFields } from 'features/common/IndexTableBase';
import { ICursor } from 'features/types';
import makeColumns from './columns';
import getProductsQuery from '../queries/getProductsQuery.gql';
import deleteProductQuery from '../queries/deleteProduct.gql';
import { makeProductUrl } from '../lib/url';
import { IProduct } from '../types';
import { PRODUCT_ICON } from '../consts';

interface IProductsTableProps extends WithTranslation {
  data: any;
  fetchMore: any;
  isLoading: boolean;
  items: any;
  history: History;
  match: IMatch<object>;
  pagination: any;
}

interface IProductsTableState {
  id?: string;
  name?: string;
  page?: string;
  perPage?: string;
  items: IProduct[];
  isLoading: boolean;
  pagination: ICursor;
}

class PageProducts extends Component<IProductsTableProps, IProductsTableState> {
  private _columns: object[];

  constructor(props: IProductsTableProps) {
    super(props);
    this._columns = makeColumns();
  }

  prepareRequestData(variables: any, data: IControlFields) {
    if (data.id) {
      variables.id = [data.id];
    }
    if (data.name) {
      set(variables, 'filterByFields.name', {
        value: String(data.name),
        operator: 'like',
      });
    }
    if (data.offers) {
      set(variables, 'filterByFields.offers', {
        operator: '=',
        value: String(data.offers),
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <CardPage
        icon={PRODUCT_ICON}
        title={t('products')}
        buttons={[{ link: makeProductUrl({ action: 'create' }), name: t('new_product') }]}
      >
        <IndexTableBase
          filterFields={['id', 'name', 'offers']}
          query={getProductsQuery}
          queryResultName="ProductQuery"
          columns={this._columns}
          prepareRequestData={this.prepareRequestData}
          deleteMutation={deleteProductQuery}
          makeCrudUrl={makeProductUrl}
          showAutoEditIconColumn={true}
          showViewIconColumn={true}
        />
      </CardPage>
    );
  }
}

export default withTranslation('products')(PageProducts);
