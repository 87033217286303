import React from 'react';
import PaperBooksForm from 'features/paperbooks/lib/PaperBooksForm';
import CardPage from 'features/ui/Page/CardPage';
import { FEATURE_ICON } from 'features/paperbooks/consts';
import { makePaperBookUrl } from 'features/paperbooks/lib/url';

class PageCreate extends React.PureComponent<{}> {
  render() {
    return (
      <CardPage
        icon={FEATURE_ICON}
        title="Создание новой бумажной книги"
        crumbs={[{ link: makePaperBookUrl(), name: 'Бумажные Книги' }]}
      >
        <PaperBooksForm />
      </CardPage>
    );
  }
}

export default PageCreate;
