import React from 'react';
import moment from 'moment';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  InfoRow, InfoRowLinkList,
  InfoRowText,
} from 'features/ui/InfoRow';
import LinkBlock from 'features/ui/LinkBlock';
import { createView } from 'features/common';
import {
  makeSeriesUrl,
  makeSeriesUrlTo,
} from '../lib/url';
import LinkToItems from '../lib/LinkToItems';
import getEntity from '../queries/getSeriesWithType.gql';

import { FEATURE_ICON } from '../consts';

import { ISeries } from '../types';

const View = createView<ISeries>(getEntity, 'SerieQuery');

class PageView extends React.PureComponent<WithTranslation> {
  renderChildren = (series: ISeries) => {
    const { t } = this.props;
    const generateLinks = () => series.items.map(item => ({
      id: item.id,
      to: `/${item.type}/${item.id}`,
      text: `[${item.type}] ${item.name}`,
    }));

    return (
      <React.Fragment>
        <LinkBlock editLink={makeSeriesUrlTo(series, 'edit')} />
        <InfoRowText label="ID" value={series.id} />
        <InfoRowText label={t('name')} value={series.name} />
        <InfoRow label={t('items:items')}>
          <LinkToItems series={series} />
        </InfoRow>
        <InfoRowLinkList
          label={t('items:items')}
          value={generateLinks()}
          byLine={true}
          max={10}
        />
        <hr />
        <InfoRowText label={t('dict:updated')} value={moment(series.updated_at).format('ll')} />
        <InfoRowText label={t('dict:created')} value={moment(series.created_at).format('ll')} />
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <View
        titleField="name"
        icon={FEATURE_ICON}
        crumbs={[{ link: makeSeriesUrl(), name: t('series') }]}
        children={this.renderChildren}
      />
    );
  }
}

export default withTranslation('categories')(PageView);
