interface IIndexSignature {
  [key: string]: any;
}
export default <T extends IIndexSignature>(values1: T, values2: T) => Object.keys(values2).reduce((acc: any, key) => {
  const add = () => acc[key] = values2[key];
  if (Array.isArray(values1[key])) {
    const a = values1[key];
    const b = values2[key];
    if (Array.isArray(b)) {
      if (a.length !== b.length) {
        add();
      } else {
        const len = a.length;
        for (let i = 0; i < len; i += 1) {
          if (a[i] !== b[i]) {
            add();
            break;
          }
        }
      }
    } else {
      add();
    }
  } else if (values1[key] !== values2[key]) {
    add();
  }
  return acc;
}, {});
