import React from 'react';
import { graphql, OptionProps } from 'react-apollo';
import { client, gql } from 'features/graphql';
import {
  IListQueryVariables,
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import {
  ILayoutQueryResponse,
} from 'features/items/types';
import ReactSelectWithId from 'features/ui/ReactSelect/ReactSelectWithId';
import { ILayout } from 'features/layouts/types';

const getLayoutsQuery = gql`
  query LayoutsQuery(
    $id: [ID]
    $page: Int
    $perPage: Int
    $filterByFields: LayoutFilterByFields
  ) {
    LayoutQuery(
      id: $id
      page: $page
      perPage: $perPage
      filterByFields: $filterByFields
    ) {
      items {
        id
        name
      }
    }
  }
`;
interface ILayoutSelectProps extends ISharedSelectProps {
  data: ILayout[];
}

interface ILayoutSelectState {
  options: ISelectOption[];
}

class LayoutSelect extends React.PureComponent<ILayoutSelectProps, ILayoutSelectState> {
  static getDerivedStateFromProps(props: ILayoutSelectProps) {
    return {
      options: LayoutSelect.mapItemsToOptions(props.data),
    };
  }

  static mapItemsToOptions = (data: ILayout[]) => data.map(item => ({
    value: +item.id,
    label: item.name,
  }));

  state = {
    options: [],
  };

  loadOptions = (value: string) => client.query<ILayoutQueryResponse>({
    query: getLayoutsQuery,
    variables: {
      pageNum: 1,
      perPage: 100,
      filterByFields: {
        name: {
          value,
          operator: 'like',
        },
      },
    },
  }).then((res) => {
    if (res.data && res.data.LayoutQuery) {
      return LayoutSelect.mapItemsToOptions(res.data.LayoutQuery.items);
    }
    return [];
  });

  noOptionsMessage = () => 'Ничего не найдено';

  render() {
    const {
      id,
      name,
      value,
      onBlur,
      onChange,
    } = this.props;

    const {
      options,
    } = this.state;

    return (
      <ReactSelectWithId
        id={id}
        name={name}
        options={options}
        async
        isMulti={false}
        loadOptions={this.loadOptions}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        noOptionsMessage={this.noOptionsMessage}
        minSearchLength={1}
      />
    );
  }
}

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, ILayoutQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;
  return {
    data: data && data.LayoutQuery ? data.LayoutQuery.items : [],
    ...ownProps,
  };
};

export default graphql<ISharedSelectProps, ILayoutQueryResponse, IListQueryVariables, ILayoutSelectProps>(getLayoutsQuery, {
  props: mapResultsToProps,
  options: ownProps => ({
    variables: {
      id: ownProps.value || undefined,
      pageNum: 1,
      perPage: 100,
    },
  }),
})(LayoutSelect);
