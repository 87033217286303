import React from 'react';
import moment from "moment";
import FormRow from 'features/ui/FormRow';
import { FormikProps } from 'formik';
import { IFormValues } from 'features/events/types';
import { WithTranslation } from 'react-i18next';
import { IItem } from 'features/items/types';
import { Button } from 'reactstrap';
import { client, gql } from 'features/graphql';
import { stubTrue, toInteger } from 'lodash';

interface IPlugnmeetFormsProps extends FormikProps<IFormValues>, WithTranslation {
  item?: IItem;
}

interface IState {
  usersJoinData: Array<{
    link: string;
    user: {
      id: string;
      email: string;
      role: string;
    };
  }>;
  records: Array<{
    id: number;
    size: number;
    url: string;
    created_at: string;
  }>;
  emptyRecords: boolean;
}

class PlugnmeetForms extends React.Component<IPlugnmeetFormsProps, IState> {
  constructor(props: IPlugnmeetFormsProps) {
    super(props);
    this.state = {
      usersJoinData: [],
      records: [],
      emptyRecords: false,
    };
  }

  handleCallLinks = () => {
    const { item } = this.props;
    const { usersJoinData } = this.state;
    if (!item || !item.id) {
      return;
    }

    const moderators = [...item.chat.moderators].map(moderator => ({
      ...moderator,
      role: 'moderator',
    }));
    const speakers = [...item.chat.speakers].map(speaker => ({
      ...speaker,
      role: 'speaker',
    }));

    const users = [...(moderators || []), ...(speakers || [])]
      .filter(user => !usersJoinData.find(join => toInteger(join.user.id) === toInteger(user.id)));

    if (users.length) {
      users.forEach((user) => {
        client.query({
          query: gql`
            query plugnmeetQuery($item_id: Int, $user_id: Int) {
              PlugnmeetQuery(item_id: $item_id, user_id: $user_id) {
                items {
                  joinUrl
                }
              }
            }
          `,
          variables: {
            item_id: toInteger(item.id),
            user_id: toInteger(user.id),
          },
        }).then((res) => {
          const link = res.data.PlugnmeetQuery.items[0].joinUrl;

          this.setState(prevState => ({ usersJoinData: [...prevState.usersJoinData, { link, user }] }));
        }).catch(err => global.console.error(`[Error]: ${err}`));
      });
    }
  }

  handleCallEventRecords = () => {
    const { item } = this.props;
    if (item) {
      client.query({
        query: gql`
          query EventRecordingQuery($item_id: Int) {
            EventRecordingQuery(item_id: $item_id) {
              items {
                id
                size
                url
                created_at
              }
            }
          }
        `,
        variables: {
          item_id: toInteger(item.id),
        },
      }).then((res) => {
        const { data } = res;
        const { items } = data.EventRecordingQuery;
        if (!items.length) {
          this.setState({ emptyRecords: true });
        }
        this.setState({ records: items });
      }).catch(err => global.console.error(`[Error]: ${err}`));
    }
  }

  render() {
    const { t } = this.props;
    const {
      usersJoinData,
      records,
      emptyRecords,
    } = this.state;

    return (
      <React.Fragment>
        <FormRow
          id="links_button"
          key="links_button"
          label={t('links_button')}
          shouldUpdate={stubTrue}
          text="Ссылки действуют 10 минут"
        >
          {
            usersJoinData.length
              ? (
                <div>
                  {usersJoinData.map(link => (
                    <>
                      <a
                        href={link.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.user.id} | {link.user.email} | <strong>{t(link.user.role)}</strong>:
                      </a>
                      <p>{link.link}</p>
                    </>
                  ))}
                </div>
              ) : null
          }
          <Button onClick={this.handleCallLinks}>
            {t('get_links')}
          </Button>
        </FormRow>
        <FormRow
          id="event_records"
          key="event_records"
          label={t('event_records')}
          shouldUpdate={stubTrue}
        >
          {records.length
            ? (
              <div>
                  {records.map(record => (
                    <p>
                      <strong>
                        {moment(toInteger(record.created_at * 1000)).format('lll')}
                        {' '}
                        {`${record.size}Мб`}
                        {' '}
                      </strong>
                      <a
                        href={record.url}
                        key={record.id}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {record.url}
                      </a>
                    </p>
                  ))}
              </div>
            ) : null}
          {emptyRecords ? <p>{t('no_records')}</p> : null}
          <Button onClick={this.handleCallEventRecords}>
          {t('get_records')}
          </Button>
        </FormRow>
      </React.Fragment>
    );
  }
}

export default PlugnmeetForms;
